<template>
  <div ref="ragTokensContainer" class="rag-tokens-container" :class="{ 'mini': isFeedMini, 'expanded': isExpanded }">
    <!-- Loading State -->
    <div v-if="isLoading" class="loading-container">
      <div class="loading-spinner"></div>
      <span>Loading...</span>
    </div>

    <!-- Main Content -->
    <v-expand-transition>
      <div v-if="isExpanded" class="rag-content">
        <!-- Fixed Header Controls -->
        <div class="rag-header sticky">
          <!-- Navigation Controls -->
          <div class="nav-controls">
            <v-btn-toggle v-model="activeView" mandatory dense class="mx-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    value="search"
                    :disabled="!hasSearchResults"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small left>mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>Search Results {{ hasSearchResults ? '' : '(No results available)' }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    value="content"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small left>mdi-text</v-icon>
                  </v-btn>
                </template>
                <span>View Content</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    value="links"
                    :disabled="!hasRelevantLinks"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small left>mdi-link-variant</v-icon>
                  </v-btn>
                </template>
                <span>Shopping & Review Links {{ hasRelevantLinks ? '' : '(No links available)' }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
          <!-- Feature Controls -->
          <div class="feature-controls">
            <template>
              <div class="ui-controls-container">
                <!-- Selected Text Highlights -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="ui-controls" v-bind="attrs" v-on="on">
                      <div v-if="selectedText && highlightCountSelected > 0" class="scroll-controls">
                        <v-slider v-model="localAutoScrollDelay" min="1000" max="20000" step="1000"
                          hide-details dense :thumb-size="24"
                          class="speed-slider mx-2" @change="updateScrollSpeed">
                        </v-slider>
                        <span class="delay-display">{{ (localAutoScrollDelay / 1000).toFixed(1) }}s</span>
                      </div>
                    </div>
                  </template>
                  <span>Adjust scroll speed for selected text</span>
                </v-tooltip>

                <!-- User Query Highlights -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="ui-controls" v-bind="attrs" v-on="on">
                      <v-icon @click="toggleHighlights('userQuery')"
                        :color="highlightControls.showUserQuery ? 'primary' : 'white'"
                        >
                        mdi-feature-search-outline
                      </v-icon>
                      <div v-if="shouldShowScrollControls('userQuery')" class="scroll-controls">
                        <v-slider v-model="localAutoScrollDelay" min="1000" max="20000" step="1000"
                          hide-details dense :thumb-size="24"
                          class="speed-slider mx-2" @change="updateAutoScrollSpeed">
                        </v-slider>
                        <span class="delay-display">{{ (localAutoScrollDelay / 1000).toFixed(1) }}s</span>
                      </div>
                    </div>
                  </template>
                  <span>{{ highlightControls.showUserQuery ? 'Hide' : 'Show' }} Query Highlights</span>
                </v-tooltip>

                <!-- Search Highlights -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="ui-controls" v-bind="attrs" v-on="on">
                      <v-icon @click="toggleHighlights('search')"
                        :color="highlightControls.showSearch ? 'primary' : 'white'"
                      >
                        mdi-magnify
                      </v-icon>
                      <div v-if="shouldShowScrollControls('search')" class="scroll-controls">
                        <v-slider v-model="localAutoScrollDelay" min="1000" max="20000" step="1000"
                          hide-details dense :thumb-size="24"
                          class="speed-slider mx-2" @change="updateAutoScrollSpeed">
                        </v-slider>
                        <span class="delay-display">{{ (localAutoScrollDelay / 1000).toFixed(1) }}s</span>
                      </div>
                    </div>
                  </template>
                  <span>{{ highlightControls.showSearch ? 'Hide' : 'Show' }} Search Highlights</span>
                </v-tooltip>

                <!-- Relevant Highlights -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="ui-controls" v-bind="attrs" v-on="on">
                      <v-icon @click="toggleHighlights('relevantSection')"
                        :color="highlightControls.showRelevant ? 'primary' : 'white'"
                      >
                        mdi-text-search
                      </v-icon>
                      <div v-if="shouldShowScrollControls('relevantSection')" class="scroll-controls">
                        <v-slider v-model="localAutoScrollDelay" min="1000" max="20000" step="1000"
                          hide-details dense :thumb-size="24"
                          class="speed-slider mx-2" @change="updateAutoScrollSpeed">
                        </v-slider>
                        <span class="delay-display">{{ (localAutoScrollDelay / 1000).toFixed(1) }}s</span>
                      </div>
                    </div>
                  </template>
                  <span>{{ highlightControls.showRelevant ? 'Hide' : 'Show' }} Relevant Highlights</span>
                </v-tooltip>

                <!-- Universal Auto-scroll Controls -->
                <div v-if="hasActiveHighlights" class="universal-scroll-controls">
                  <v-btn small icon class="control-btn" @click="toggleAutoScroll" color="primary">
                    <v-icon>{{ autoScrollSettings.isActive ? 'mdi-pause' : 'mdi-play' }}</v-icon>
                  </v-btn>
                  <v-btn small icon class="control-btn" @click="resetHighlights" color="error">
                    <v-icon small>mdi-reload</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>

            <!-- Toggle RAG Content -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small @click="toggleRagContent" v-bind="attrs" v-on="on">
                  <v-icon>{{ showRagContent ? 'mdi-close' : 'mdi-eye-check-outline' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ showRagContent ? 'Done checking' : 'Check for Hallucinations' }}</span>
            </v-tooltip>
          </div>
        </div>

        <!-- Scrollable Content Sections -->
        <div class="content-sections" ref="contentSections">

          <!-- Search Results Section -->
          <div v-show="activeView === 'search' && hasSearchResults"
               class="search-results-grid"
               ref="searchSection">
            <div v-for="result in searchResults"
                :key="result.id"
                class="search-result">
                <div class="result-image">
                  <img
                    v-if="result.thumbnail || result.image"
                    :src="result.thumbnail || result.image"
                    :alt="result.title"
                    @error="handleImageError($event, result)"
                  >
                  <svg
                    v-else
                    width="120"
                    height="90"
                    viewBox="0 0 120 90"
                    class="placeholder-svg"
                  >
                    <!-- Gradient and pattern definitions -->
                    <defs>
                      <linearGradient id="placeholder-grad" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" style="stop-color:#f3f4f6"/>
                        <stop offset="100%" style="stop-color:#e5e7eb"/>
                      </linearGradient>
                      <pattern id="placeholder-pattern" x="0" y="0" width="10" height="10" patternUnits="userSpaceOnUse">
                        <circle cx="5" cy="5" r="1" fill="#d1d5db" opacity="0.3"/>
                      </pattern>
                    </defs>
                    <!-- Background with gradient -->
                    <rect
                      width="120"
                      height="90"
                      fill="url(#placeholder-grad)"
                      rx="4"
                      ry="4"
                    />
                    <!-- Pattern overlay -->
                    <rect
                      width="120"
                      height="90"
                      fill="url(#placeholder-pattern)"
                      rx="4"
                      ry="4"
                    />
                    <!-- Modern icon -->
                    <g transform="translate(45,25)">
                      <path
                        d="M0 25 L10 15 L20 22 L30 5 L40 25"
                        stroke="#9ca3af"
                        stroke-width="2"
                        fill="none"
                      />
                      <circle
                        cx="25"
                        cy="10"
                        r="4"
                        fill="#9ca3af"
                      />
                    </g>
                    <!-- Text background for better readability -->
                    <rect
                      x="10"
                      y="60"
                      width="100"
                      height="20"
                      fill="rgba(255,255,255,0.9)"
                      rx="2"
                    />
                    <!-- Truncated title text -->
                    <text
                      x="60"
                      y="74"
                      text-anchor="middle"
                      fill="#4b5563"
                      font-size="10"
                      font-family="system-ui, -apple-system, sans-serif"
                    >{{ truncateTitle(result.title) }}</text>
                  </svg>
                </div>
              <div class="result-title">
                {{ result.title || 'No title available' }}
              </div>
              <div class="result-date">
                {{ formatDate(result.date) || 'No date avilable' }}
              </div>
              <a :href="result.url"
                target="_blank"
                rel="noopener"
                class="result-url">
                {{ formatDomain(result.url) }}
              </a>
              <div class="result-description">
                {{ result.description || 'No description available' }}
              </div>
            </div>
          </div>

          <!-- Shopping & Review Links Section -->
          <template>
            <div v-show="activeView === 'links' && hasRelevantLinks" class="modern-links-section" ref="linksSection">
              <div class="links-container">
                <!-- Shopping Links Card -->
                <div v-if="extractRelevantLinks().shopping.length" class="links-card shopping">
                  <div class="card-header">
                    <div class="header-content">
                      <v-icon color="primary" size="24">mdi-shopping-outline</v-icon>
                      <h3>Shopping Links</h3>
                      <v-chip x-small color="primary" text-color="white" class="ml-2">
                        {{ extractRelevantLinks().shopping.length }}
                      </v-chip>
                    </div>
                  </div>

                  <div class="links-grid" :class="{ 'expanded': isShoppingLinksExpanded }">
                    <div v-for="(link, index) in displayedShoppingLinks"
                        :key="'shop-' + index"
                        class="link-card">
                      <div class="link-card-content">
                        <div class="link-icon">
                          <v-icon color="primary">mdi-shopping</v-icon>
                        </div>
                        <div class="link-details">
                          <a :href="link.url"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="link-url">
                            {{ formatDomain(link.url) }}
                          </a>
                          <span class="link-meta">{{ link.price || 'Price not available' }}</span>
                        </div>
                        <v-btn icon small class="visit-btn" :href="link.url" target="_blank">
                          <v-icon small>mdi-open-in-new</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <div v-if="extractRelevantLinks().shopping.length > 3" class="card-footer">
                    <v-btn text small @click="isShoppingLinksExpanded = !isShoppingLinksExpanded">
                      <v-icon left x-small>{{ isShoppingLinksExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      {{ isShoppingLinksExpanded ? 'Show Less' : `Show ${extractRelevantLinks().shopping.length - 3} More` }}
                    </v-btn>
                  </div>
                </div>

                <!-- Review Links Card -->
                <div v-if="extractRelevantLinks().reviews.length" class="links-card reviews">
                  <div class="card-header">
                    <div class="header-content">
                      <v-icon color="amber darken-2" size="24">mdi-star-check-outline</v-icon>
                      <h3>Review Links</h3>
                      <v-chip x-small color="amber darken-2" text-color="white" class="ml-2">
                        {{ extractRelevantLinks().reviews.length }}
                      </v-chip>
                    </div>
                  </div>

                  <div class="links-grid" :class="{ 'expanded': isReviewLinksExpanded }">
                    <div v-for="(link, index) in displayedReviewLinks"
                        :key="'review-' + index"
                        class="link-card">
                      <div class="link-card-content">
                        <div class="link-icon">
                          <v-icon color="amber darken-2">mdi-star</v-icon>
                        </div>
                        <div class="link-details">
                          <a :href="link.url"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="link-url">
                            {{ formatDomain(link.url) }}
                          </a>
                          <span class="link-meta">{{ link.rating || 'Rating not available' }}</span>
                        </div>
                        <v-btn icon small class="visit-btn" :href="link.url" target="_blank">
                          <v-icon small>mdi-open-in-new</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <div v-if="extractRelevantLinks().reviews.length > 3" class="card-footer">
                    <v-btn text small @click="isReviewLinksExpanded = !isReviewLinksExpanded">
                      <v-icon left x-small>{{ isReviewLinksExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      {{ isReviewLinksExpanded ? 'Show Less' : `Show ${extractRelevantLinks().reviews.length - 3} More` }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- Main Content Section -->
          <section v-show="activeView === 'content'" class="section content-section" ref="contentSection">
            <v-card flat>
              <v-card-text class="text--primary">
                <v-text-field v-model="searchQuery"
                    label="Search | Select text | Click a citation"
                    prepend-icon="mdi-magnify"
                    clearable
                    @input="debouncedHandleSearchQuery"
                    dense
                    :disabled="isSearchDisabled"
                    :hint="searchDisabledHint">
                  <template v-slot:append>
                    <!-- Search disabled warning -->
                    <v-tooltip bottom v-if="isSearchDisabled">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning" v-bind="attrs" v-on="on">
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>Search is disabled for large documents to maintain performance.</span>
                    </v-tooltip>
                    <!-- Highlight counter -->
                    <v-chip v-if="getCurrentHighlightCount() > 0" small class="ml-2">
                      {{ currentHighlightType === 'relevantSection'
                          ? `${Math.min(highlightIndex + 1, relevantSections.length)} / ${relevantSections.length}`
                          : `${highlightIndex + 1} / ${getCurrentHighlightCount()}` }}
                      <span class="ml-1 text-caption">{{ getHighlightTypeLabel() }}</span>
                    </v-chip>
                  </template>
                </v-text-field>
                <div ref="ragContentText" class="rag-content-text" v-html="highlightedContent.content"></div>
              </v-card-text>
            </v-card>
          </section>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import { debounce } from 'lodash';
import { isStopWord, getKeywordsFromQuery, escapeRegExp, STOP_WORDS, SYNONYMS, CONTEXT_MAP } from './utils/textUtils';
import HallucinationIcon from './custom_icons/HallucinationIcon.vue';

const MAX_HIGHLIGHTS = 200;
const MAX_TOKEN_FOR_RAG_SEARCH = 150000;
const MINIMUM_RELEVANCE_THRESHOLD = 0.45;
const MAX_RELEVANT_HIGHLIGHTS = 20;

const SHOPPING_DOMAINS = [
  // Major marketplaces
  'amazon', 'ebay', 'walmart', 'etsy', 'bestbuy', 'target', 'aliexpress', 'wish',
  'newegg', 'overstock', 'wayfair', 'homedepot', 'lowes', 'costco', 'sams',

  // Fashion and apparel
  'asos', 'zappos', 'nordstrom', 'macys', 'fashionnova', 'hm', 'zara', 'uniqlo',
  'forever21', 'adidas', 'nike', 'reebok', 'puma', 'underarmour', 'footlocker',

  // Tech and electronics
  'apple', 'microsoft', 'dell', 'hp', 'lenovo', 'samsung', 'lg', 'sony',
  'bhphotovideo', 'adorama', 'microcenter', 'cdw', 'crucial', 'corsair',

  // Specialty retail
  'chewy', 'petco', 'petsmart', 'michaels', 'joann', 'hobbylobby', 'dickssportinggoods',
  'rei', 'cabelas', 'autozone', 'advanceautoparts', 'gamestop', 'steam',

  // Luxury and department stores
  'bloomingdales', 'neimanmarcus', 'saksfifthavenue', 'barneys', 'bergdorfgoodman',
  'harrods', 'selfridges', 'farfetch', 'ssense', 'mrporter',

  // Home and furniture
  'ikea', 'ashleyfurniture', 'roomstogo', 'pier1', 'crateandbarrel', 'westelm',
  'potterybarn', 'bedbathandbeyond', 'williams-sonoma',

  // Generic shopping terms
  'shop', 'store', 'buy', 'product', 'shopping', 'checkout', 'cart', 'purchase',
  'deal', 'sale', 'discount', 'outlet', 'marketplace', 'price', 'order',

  // International marketplaces
  'rakuten', 'taobao', 'jd', 'flipkart', 'lazada', 'shopee', 'zalando', 'asos',

  // Grocery and food
  'instacart', 'freshdirect', 'boxed', 'shipt', 'peapod', 'groceries',

  // Subscription boxes
  'birchbox', 'ipsy', 'boxycharm', 'hellofresh', 'blueapron', 'subscription'
];

const REVIEW_TERMS = [
  // Review indicators
  'review', 'rating', 'reviews', 'ratings', 'starred', 'stars', 'feedback',
  'testimonial', 'testimonials', 'opinion', 'opinions', 'critique', 'evaluation',

  // Comparison terms
  'comparison', 'compare', 'vs', 'versus', 'differences', 'similarities',
  'alternative', 'alternatives', 'competitor', 'competitors', 'competing',

  // Testing terms
  'test', 'testing', 'tested', 'benchmark', 'benchmarking', 'benchmarks',
  'performance', 'score', 'scoring', 'measurement', 'measurements',

  // Analysis terms
  'analysis', 'analyze', 'analyzed', 'examination', 'examine', 'examined',
  'assessment', 'assess', 'assessed', 'evaluation', 'evaluate', 'evaluated',

  // Hands-on experience
  'hands-on', 'experience', 'experienced', 'trial', 'tried', 'usage', 'used',
  'testing', 'tested', 'real-world', 'practical', 'practice',

  // Technical assessment
  'technical', 'specification', 'specs', 'detail', 'detailed', 'breakdown',
  'deep-dive', 'in-depth', 'comprehensive', 'thorough', 'complete',

  // User feedback
  'user', 'customer', 'buyer', 'owner', 'ownership', 'verified', 'purchased',
  'feedback', 'report', 'reported', 'recommendation', 'recommended',

  // Professional reviews
  'expert', 'professional', 'pro', 'editorial', 'editor', 'laboratory',
  'lab-tested', 'certified', 'verification', 'verified',

  // Review sites
  'trustpilot', 'yelp', 'consumerreports', 'cnet', 'techradar', 'wirecutter',
  'rtings', 'pcmag', 'tomsguide', 'engadget',

  // Comparison sites
  'versus', 'compare', 'comparison', 'versusio', 'gsmarena', 'productchart',

  // Rating indicators
  'star', 'score', 'rating', 'ranked', 'ranking', 'rate', 'rated',
  'best', 'worst', 'top', 'bottom', 'recommended', 'not-recommended',

  // Review quality indicators
  'detailed', 'comprehensive', 'in-depth', 'complete', 'thorough', 'extensive',
  'ultimate', 'definitive', 'authoritative', 'expert'
];

export default {
  name: 'RagTokens',
  emits: ['update:selected-text', 'update-chat-input'],  // Declare emits for parent components
  components: {
    HallucinationIcon,
  },
  props: {
    autoScrollDelay: {
      type: Number,
      default: 5000
    },
    searchResults: {
      type: Array,
      default: () => []
    },
    ragTokens: {
      type: Number,
      required: true,
    },
    ragType: {
      type: String,
      default: 'llm_passthrough'
    },
    isFeedMini: {
      type: Boolean,
      default: true
    },
    selectedText: {
    type: String,
    default: ''
    },
    latestUserInput: {
      type: String,
      default: ''
    },
    showSparks: {
      type: Boolean,
      default: false
    },
    socket: WebSocket,
  },
  data() {
    return {
      localAutoScrollDelay: this.autoScrollDelay,  // Local copy of auto-scroll delay prop
      highlightControls: {
        showUserQuery: false,
        showSearch: false,
        showSelected: false,
        showRelevant: false
      },
      activeHighlightType: null,
      autoScrollSettings: {
        isActive: false,
        delay: this.autoScrollDelay,
        currentType: null,
        timeoutId: null,
        lastPosition: 0,
      },
      activeView: 'content',
      autoScrollTimer: null,
      isAutoScrolling: true,
      scrollInterval: null,
      scrollSettings: {
        SCROLL_DELAY: 1800,
        SCROLL_DURATION: 1000,  // easy on the eyes
        SCROLL_POSITION: 0.4,
        SCROLL_PADDING: 100,
      },
      isRelevantScrolling: false,
      relevantScrollTimeout: null,
      isScrolling: false,
      searchScrollTimeout: null,
      userQueryScrollTimeout: null,
      currentScrollTimeout: null,
      isReviewLinksExpanded: false,
      isShoppingLinksExpanded: false,
      isLoading: true,
      relevantSections: [], // Will store analyzed sections
      showRelevantHighlights: true,
      sectionHighlightsVisible: false,
      isExpanded: false,
      showRagContent: false,
      searchQuery: '',
      lastSearchQuery: '',
      isUserQueryHighlightDisabled: false,
      isSearchDisabled: false,
      searchDisabledHint: '',
      maxHighlights: 100,
      isSearching: false,
      ragTokenDigits: [0, 0, 0, 0, 0],
      highlightIndex: -1,
      highlightCountSearch: 0,
      highlightCountSelected: 0,
      highlightCountUserQuery: 0,
      highlightCountRelevant: 0,
      currentHighlightType: null,
      showUserQueryHighlights: false,
      localRagTokens: this.ragTokens,
      localRagContent: '',
      localRagType: this.ragType,
      contentSource: 'activeObject',
    }
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
    ...mapState('websocket', ['user', 'showTempMessage', 'activeObject', 'activeObjectContent', 'objectId']),
    ...mapFields('websocket', [
          'showTempMessage',
          'user',
      ]),
    displayedShoppingLinks() {
      const links = this.extractRelevantLinks().shopping;
      return this.isShoppingLinksExpanded ? links : links.slice(0, 3);
    },
    displayedReviewLinks() {
      const links = this.extractRelevantLinks().reviews;
      return this.isReviewLinksExpanded ? links : links.slice(0, 3);
    },
    hasRelevantLinks() {
      const links = this.extractRelevantLinks();
      return links.shopping.length > 0 || links.reviews.length > 0;
    },
    activeHighlightCount() {
      return this.activeHighlightType ? this.getHighlightCount(this.activeHighlightType) : 0;
    },
    hasSearchResults() {
      return this.searchResults && this.searchResults.length > 0;
    },
    badgeContent() {
      if (!this.activeObject || !this.activeObjectContent) return null;

      const typeMap = {
        'FileSubmission': 'F',
        'TextSubmission': 'T',
        'ChatMessage': 'C',
        'YouTubeTranscriptSubmission': 'YT',
        'IndividualContact': 'IC',
      };

      const prefix = typeMap[this.activeObject] || '';
      return `${prefix}#${this.objectId}`;
    },

    showBadge() {
      return !this.isExpanded && this.activeObject && this.activeObjectContent;
    },
    effectiveRagTokens() {
      if (this.contentSource === 'activeObject' && this.activeObjectContent) {
        return this.activeObjectContent.original_tokens || 0;
      }
      return this.localRagTokens || 0;
    },

    effectiveRagContent() {
      if (this.contentSource === 'activeObject' && this.activeObjectContent) {
        return this.activeObjectContent.rag_content || '';
      }
      return this.localRagContent || '';
    },

    effectiveRagType() {
      if (this.contentSource === 'activeObject' && this.activeObjectContent) {
        return this.activeObjectContent.rag_strategy || 'llm_passthrough';
      }
      return this.localRagType || 'llm_passthrough';
    },
    tokenCount() {
      return this.ragTokens;
    },
    ragTypeText() {
      const type = this.effectiveRagType || '';
      return type.toString().toUpperCase();
    },
    parsedRagContent() {
      return this.replaceURLsAndEntities(this.effectiveRagContent);
    },

    highlightedContent() {
      let content = this.parsedRagContent
      let userQueryCount = 0
      let searchCount = 0
      let selectedCount = 0
      const highlightCountRelevant = this.relevantSections.length
      let suggestedHighlightType = null

      // First apply relevant section highlights if they exist and are enabled
      if (this.showRelevantHighlights && this.relevantSections.length > 0) {
        // Sort sections by index in reverse to maintain positions
        const sortedSections = [...this.relevantSections]
          .sort((a, b) => b.index - a.index)

        sortedSections.forEach(section => {
          const escapedText = this.escapeRegExp(section.text)
          const relevanceClass = this.getScoreClass(section.score)

          const regex = new RegExp(`(${escapedText})`, "g")
          content = content.replace(regex, (match) => {
            return `<span class="highlight-relevant-section highlight-semantic-match ${relevanceClass}"
                        data-score="${section.score}"
                        title="Relevance: ${Math.round(section.score * 100)}%">${match}</span>`
          })
        })

        suggestedHighlightType = "relevantSection"
      }

      // Highlight user query words only if showUserQueryHighlights is true
      if (this.showUserQueryHighlights && !this.isUserQueryHighlightDisabled) {
        const userWords = this.latestUserInput.toLowerCase().split(/\s+/)
        userWords.forEach(word => {
          if (word.length > 2 && !STOP_WORDS.has(word)) {
            const regex = new RegExp(`\\b${this.escapeRegExp(word)}\\b`, "gi")
            content = content.replace(regex, (match, index) => {
              userQueryCount++
              return `<span class="highlight-user-query" data-word="${word}" data-index="${index}">${match}</span>`
            })
          }
        })

        if (!suggestedHighlightType && userQueryCount > 0) {
          suggestedHighlightType = "userQuery"
        }
      }

      // Highlight search query
      if (this.searchQuery) {
        const searchRegex = new RegExp(this.escapeRegExp(this.searchQuery), "gi")
        content = content.replace(searchRegex, (match, index) => {
          searchCount++
          return `<span class="highlight-search" data-word="${this.searchQuery}" data-index="${index}">${match}</span>`
        })

        if (!suggestedHighlightType && searchCount > 0) {
          suggestedHighlightType = "search"
        }
      }

      // Highlight selected text
      if (this.selectedText) {
        const selectedRegex = new RegExp(this.escapeRegExp(this.selectedText), "gi")
        content = content.replace(selectedRegex, (match, index) => {
          selectedCount++
          return `<span class="highlight-selected" data-word="${this.selectedText}" data-index="${index}">${match}</span>`
        })

        if (!suggestedHighlightType && selectedCount > 0) {
          suggestedHighlightType = "selected"
        }
      }

      return {
        content,
        userQueryCount,
        searchCount,
        selectedCount,
        relevantCount: highlightCountRelevant,
        suggestedHighlightType
      }
    },
  },
  created() {
    this.debouncedHandleSearchQuery = debounce(this.handleSearchQuery, 500);
  },
  watch: {
    autoScrollDelay(newDelay) {
      this.localAutoScrollDelay = newDelay;
      this.updateAutoScrollSpeed();
    },
    activeView(newView) {
      this.$nextTick(() => {
        if (newView === 'search' && this.hasSearchResults) {
          this.revealSection('searchSection')
        } else if (newView === 'content') {
          this.scrollToContent()
        }
      })
    },
    searchResults: {
      handler(newResults) {
        if (newResults && newResults.length > 0) {
          this.updateHighlightCount()
          this.updateRagContentVisibility(true)
          this.updateLocalData()
          this.showRagContent = true
          this.isExpanded = true
          this.activeView = 'search'
        }
      },
      immediate: true
    },
    latestUserInput: {
      handler(newInput) {
        if (!newInput) return;

        this.$nextTick(() => {
          // Clear existing highlights first
          this.clearHighlights();

          // Analyze and highlight new content
          this.analyzeContent();

          // Only scroll if we found relevant sections
          if (this.relevantSections.length > 0) {
            this.showRelevantHighlights = true;
            this.currentHighlightType = 'relevantSection';
            this.highlightIndex = 0;

            // Use setTimeout to ensure DOM is updated
            setTimeout(() => {
              this.scrollToHighlightByIndex();
            }, 100);
          }
        });
      },
      immediate: true
    },
    activeObjectContent: {
      handler(newContent) {
        if (newContent) {
          this.$nextTick(() => {
            // Clear existing highlights first
            this.clearHighlights();

            // stop auto-scroll
            this.stopAutoScroll();
            if (this.autoScrollTimer) {
              clearTimeout(this.autoScrollTimer)
            }

            this.setupClickHandlers();
            this.isExpanded = true;
            this.showRagContent = true;
            this.$emit('update:showRagContent', true);
            this.updateHighlightCount();
            this.contentSource = 'activeObject';
            this.localRagContent = this.effectiveRagContent;
            this.localRagTokens = this.effectiveRagTokens;
            this.updateLocalData();
            this.updateRagContentVisibility(true);
            if (this.autoScrollTimer) {
            clearTimeout(this.autoScrollTimer)
            }
            // Set up new auto-scroll timer to show RAG content after search results display
            this.autoScrollTimer = setTimeout(() => {
              this.activeView = 'content'
            }, 3000)
            this.highlightIndex = 0;
            this.$nextTick(() => {
              setTimeout(() => {
                this.scrollToHighlightByIndex();
              }, 100);
            });
          });
        } else {
          this.isExpanded = false;
          this.showRagContent = false;
          this.$emit('update:showRagContent', false);
        }
      },
    },
    ragTokens(newTokens) {
      if (newTokens > MAX_TOKEN_FOR_RAG_SEARCH) {
        this.isSearchDisabled = true;
        this.searchDisabledHint = `Search is disabled for large documents to maintain performance.`;
      }
    },
    searchQuery() {
      this.debouncedHandleSearchQuery();
    },
    selectedText(newText) {
      // Clear any existing auto-scroll timeout
      if (this.currentScrollTimeout) {
        clearTimeout(this.currentScrollTimeout);
        this.currentScrollTimeout = null;
      }

      // stop auto-scroll
      this.stopAutoScroll();


      if (newText) {
        this.$nextTick(() => {
          this.isExpanded = true;
          this.showRagContent = true;
          this.$emit('update:showRagContent', true);
          this.updateHighlightCount();
          this.currentHighlightType = 'selected';
          this.contentSource = 'activeObject';
          this.localRagContent = this.effectiveRagContent;
          this.localRagTokens = this.effectiveRagTokens;
          this.updateLocalData();
          this.updateRagContentVisibility(true);
          this.highlightIndex = 0;
          // set active view to content
          this.activeView = 'content';

          if (this.highlightCountSelected > 0) {
            this.autoScrollThroughSelectedText();
          }
        });
      }
    },
  },
  mounted() {
    this.handleSectionVisibility();
    // Listen for rag view limit response
    this.$parent.$on('rag-view-limit-response', this.handleRagViewLimitResponse);
    this.setupSelectionListener();
    // Initial setup attempt
    this.setupClickHandlers();
    this.isLoading = false;
},

updated() {
  // Other update logic
  this.isLoading = false;
},

beforeDestroy() {
  this.stopAutoScroll();
  if (this.autoScrollTimer) {
    clearTimeout(this.autoScrollTimer)
  }
  if (this.observer) {
      this.observer.disconnect();
    }
  if (this.$refs.ragContentText) {
    this.$refs.ragContentText.removeEventListener('click', this.handleCombinedClick);
  }
  this.$parent.$off('rag-view-limit-response', this.handleRagViewLimitResponse);
  this.cleanupSelectionListener();
  if (this.currentScrollTimeout) {
    clearTimeout(this.currentScrollTimeout);
    this.currentScrollTimeout = null;
  }
   // Clear any pending timeouts when component is destroyed
  if (this.userQueryScrollTimeout) {
    clearTimeout(this.userQueryScrollTimeout);
    this.userQueryScrollTimeout = null;
  }
  if (this.searchScrollTimeout) {
    clearTimeout(this.searchScrollTimeout);
    this.searchScrollTimeout = null;
  }
},
  beforeUnmount() {
    // Remove the listener when the component is destroyed
    this.$parent.$off('rag-view-limit-response', this.handleRagViewLimitResponse);
  },
  methods: {
    ...mapActions('websocket', ['showTempMessageFn']),
    isStopWord,
    getKeywordsFromQuery,
    escapeRegExp,
    handleImageError(e) {
      console.log('Image failed to load:', e.target.src);  // For debugging
      e.target.style.display = 'none';
      e.target.parentElement.style.display = 'none';  // Hide the container too
    },
    getScoreClass(score) {
      if (score > 0.9) {
        return 'highlight-relevant-very-high';
      } else if (score > 0.8) {
        return 'highlight-relevant-high';
      } else if (score > 0.75) {
        return 'highlight-relevant-medium';
      } else {
        return 'highlight-relevant-low';
      }
    },
    truncateTitle(title) {
      return title ? (title.length > 15 ? title.substring(0, 15) + '...' : title) : 'No Title';
    },
    updateScrollSpeed() {
      if (this.currentHighlightType === 'selected' && this.highlightCountSelected > 0) {
        // Clear existing scroll timeout
        if (this.currentScrollTimeout) {
          clearTimeout(this.currentScrollTimeout);
          this.currentScrollTimeout = null;
        }
        // Restart auto-scroll with new delay
        this.autoScrollThroughSelectedText();
      }
    },
    updateUserQueryHighlights() {
      if (!this.$refs.ragContentText) return;

      const contentElement = this.$refs.ragContentText;

      if (this.highlightControls.showUserQuery && this.latestUserInput) {
        const userWords = this.latestUserInput.toLowerCase().split(/\s+/);
        let content = contentElement.innerHTML;

        userWords.forEach(word => {
          if (word.length > 2 && !this.isStopWord(word)) {
            const regex = new RegExp(`\\b${this.escapeRegExp(word)}\\b`, 'gi');
            content = content.replace(regex, match =>
              `<span class="highlight-user-query" data-word="${word}">${match}</span>`
            );
          }
        });

        contentElement.innerHTML = content;
        this.updateHighlightCount();

        if (this.highlightCountUserQuery > 0) {
          this.currentHighlightType = 'userQuery';
          this.highlightIndex = 0;
          this.scrollToHighlightByIndex();
        }
      }
    },

    updateSearchHighlights() {
      if (!this.$refs.ragContentText || !this.searchQuery) return;

      const contentElement = this.$refs.ragContentText;

      if (this.highlightControls.showSearch) {
        let content = contentElement.innerHTML;
        const searchRegex = new RegExp(this.escapeRegExp(this.searchQuery), 'gi');

        content = content.replace(searchRegex, match =>
          `<span class="highlight-search" data-word="${this.searchQuery}">${match}</span>`
        );

        contentElement.innerHTML = content;
        this.updateHighlightCount();

        if (this.highlightCountSearch > 0) {
          this.currentHighlightType = 'search';
          this.highlightIndex = 0;
          this.scrollToHighlightByIndex();
        }
      }
    },

    updateRelevantHighlights() {
      if (!this.$refs.ragContentText) return;

      const contentElement = this.$refs.ragContentText;

      if (this.highlightControls.showRelevant && this.relevantSections.length > 0) {
        let content = contentElement.innerHTML;

        // Sort sections by index in reverse to maintain positions
        const sortedSections = [...this.relevantSections].sort((a, b) => b.index - a.index);

        sortedSections.forEach(section => {
          const escapedText = this.escapeRegExp(section.text);
          const relevanceClass = this.getScoreClass(section.score);

          const regex = new RegExp(`(${escapedText})`, 'g');
          content = content.replace(regex, (match) => {
            return `<span class="highlight-relevant-section highlight-semantic-match ${relevanceClass}"
                          data-score="${section.score}"
                          title="Relevance: ${Math.round(section.score * 100)}%">${match}</span>`;
          });
        });

        contentElement.innerHTML = content;
      }
    },

    // Helper method for all highlight updates
    updateAllHighlights() {
      this.$nextTick(() => {
        if (this.highlightControls.showUserQuery) {
          this.updateUserQueryHighlights();
        }
        if (this.highlightControls.showSearch) {
          this.updateSearchHighlights();
        }
        if (this.highlightControls.showRelevant) {
          this.updateRelevantHighlights();
        }
      });
    },
    formatDate(dateStr) {
      if (!dateStr) return 'No date available';

      // Handle ISO dates
      if (dateStr.includes('T')) {
        return new Date(dateStr).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }

      // For dates already in readable format, return as is
      return dateStr;
    },
    toggleHighlights(type) {
      // Toggle the highlight visibility
      this.highlightControls[`show${type.charAt(0).toUpperCase() + type.slice(1)}`] =
        !this.highlightControls[`show${type.charAt(0).toUpperCase() + type.slice(1)}`];

      if (this.highlightControls[`show${type.charAt(0).toUpperCase() + type.slice(1)}`]) {
        // If turning on highlights, make this the active type
        this.autoScrollSettings.currentType = type;
        this.cuurentHighlightType = type;
        this.startAutoScroll(type);
      } else {
        // If turning off highlights, stop auto-scroll if it was the active type
        if (this.autoScrollSettings.currentType === type) {
          this.currentHighlightType = null;
          this.autoScrollSettings.currentType = null;
          this.stopAutoScroll();
        }
      }

      // Update highlights based on type
      this.$nextTick(() => {
        switch(type) {
          case 'userQuery':
            this.updateUserQueryHighlights();
            break;
          case 'search':
            this.updateSearchHighlights();
            break;
          case 'relevantSection':
            this.updateRelevantHighlights();
            break;
        }
      });
    },

    shouldShowScrollControls(type) {
      return this.highlightControls[`show${type.charAt(0).toUpperCase() + type.slice(1)}`] &&
             this.getHighlightCount(type) > 0;
    },

    getHighlightCount(type) {
      switch(type) {
        case 'userQuery':
          return this.highlightCountUserQuery;
        case 'search':
          return this.highlightCountSearch;
        case 'relevantSection':
          return this.relevantSections.length;
        case 'selected':
          return this.highlightCountSelected;
        default:
          return 0;
      }
    },

    hasActiveHighlights() {
      return Object.values(this.highlightControls).some(value => value) &&
             this.getHighlightCount(this.activeHighlightType) > 0;
    },

    resetHighlights() {
      if (this.autoScrollSettings.currentType) {
        this.highlightIndex = 0;
        this.startAutoScroll(this.autoScrollSettings.currentType);
      }
    },
    handleSectionVisibility() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('visible');
            }
          });
        },
        { threshold: 0.1 }
      );

      ['searchSection', 'linksSection', 'contentSection'].forEach(ref => {
        if (this.$refs[ref]) {
          observer.observe(this.$refs[ref]);
        }
      });
    },
    async revealContent() {
      if (this.searchResults.length) {
        this.activeView = 'search'
        await this.revealSection('searchSection')
      }
    },
    async revealSection(sectionRef) {
      const section = this.$refs[sectionRef];
      if (section) {
        section.classList.add('visible');
        this.visibleSections.add(sectionRef);
        await this.$nextTick();
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },

    scrollToContent() {
      const contentSection = this.$refs.contentSection;
      if (contentSection) {
        contentSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },

    scrollToSection(sectionRef) {
      const section = this.$refs[sectionRef];
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    toggleAutoScroll() {
      if (this.autoScrollSettings.isActive) {
        this.stopAutoScroll();
      } else if (this.autoScrollSettings.currentType) {
        // Resume from last position
        this.highlightIndex = this.autoScrollSettings.lastPosition || 0;
        this.startAutoScroll(this.autoScrollSettings.currentType);
      }
    },

    startAutoScroll(highlightType) {
      // Don't reset the highlight index if we're resuming the same type
      if (this.autoScrollSettings.currentType !== highlightType) {
        this.highlightIndex = 0;
      }

      this.autoScrollSettings = {
        isActive: true,
        delay: this.localAutoScrollDelay,
        currentType: highlightType,
        timeoutId: null,
        // Store the last position
        lastPosition: this.highlightIndex
      };

      this.currentHighlightType = highlightType;
      this.scrollToNextHighlight();
    },


    stopAutoScroll() {
      if (this.autoScrollSettings.timeoutId) {
        clearTimeout(this.autoScrollSettings.timeoutId);
      }

      // Store the current position before stopping
      this.autoScrollSettings = {
        ...this.autoScrollSettings,
        isActive: false,
        timeoutId: null,
        lastPosition: this.highlightIndex
      };
    },

    updateAutoScrollSpeed() {
      if (this.autoScrollSettings.isActive) {
        this.autoScrollSettings.delay = this.localAutoScrollDelay;
        // Restart scrolling with new delay
        this.stopAutoScroll();
        this.startAutoScroll(this.autoScrollSettings.currentType);
      }
    },
    clearHighlights() {
      this.$nextTick(() => {
        this.resetHighlightCount();
        this.currentHighlightType = null;
        this.highlightIndex = -1;
        this.$refs.ragContentText.querySelectorAll('.highlight-search, .highlight-selected, .highlight-user-query, highlight-relevant-section').forEach(el => {
          el.classList.remove('highlight-search', 'highlight-selected', 'highlight-user-query', 'highlight-relevant-section');
        });
      });
    },
    extractPrices(text) {
      // Match common price patterns
      const priceRegex = /\$\d+(?:,\d{3})*(?:\.\d{2})?|\d+(?:,\d{3})*(?:\.\d{2})?\s?(?:USD|dollars)/gi;
      const prices = text.match(priceRegex) || [];

      // Remove duplicates and sort
      return [...new Set(prices)].sort((a, b) => {
        const getAmount = (price) => parseFloat(price.replace(/[$,]/g, ''));
        return getAmount(a) - getAmount(b);
      });
    },
    extractRelevantLinks() {
      if (!this.effectiveRagContent) return { shopping: [], reviews: [] };

      const urlRegex = /\[URL:\s*(https?:\/\/[^\]]+)\]/g;
      const hrefRegex = /\[HREF:\s*[^|]+\|(https?:\/\/[^\]]+)\]/g;

      const urlMatches = [...this.effectiveRagContent.matchAll(urlRegex)].map(match => match[1]);
      const hrefMatches = [...this.effectiveRagContent.matchAll(hrefRegex)].map(match => match[1]);
      const allUrls = [...urlMatches, ...hrefMatches];

      const SHOPPING_DOMAIN_SET = new Set(SHOPPING_DOMAINS);
      const shopping = [];
      const reviews = [];

      const isValidUrl = (urlString) => {
        try {
          new URL(urlString);
          return true;
        } catch {
          console.warn('Invalid URL:', urlString);
          return false;
        }
      };

      const isAmazonReview = (url) => {
        try {
          const pathname = new URL(url).pathname.toLowerCase();
          return pathname.includes('/customer-reviews/') ||
                pathname.includes('/review/') ||
                pathname.includes('/reviews/');
        } catch {
          return false;
        }
      };

      const isShoppingLink = (url) => {
        try {
          const hostname = new URL(url).hostname;
          const parts = hostname.split('.');
          for (let i = parts.length - 2; i >= 0; i--) {
            if (SHOPPING_DOMAIN_SET.has(parts[i])) {
              return true;
            }
          }
          return false;
        } catch {
          return false;
        }
      };

      allUrls.forEach(url => {
        if (!isValidUrl(url)) return;

        try {
          const lowerUrl = url.toLowerCase();
          const urlObj = new URL(url);
          const domain = urlObj.hostname.replace('www.', '');
          const isAmazon = domain.includes('amazon.');

          if (isAmazon) {
            if (isAmazonReview(url)) {
              reviews.push({ url, domain });
            } else {
              shopping.push({ url, domain });
            }
          } else if (isShoppingLink(url)) {
            shopping.push({ url, domain });
          }

          // Add to reviews if it contains review terms (for non-Amazon sites)
          if (!isAmazon && REVIEW_TERMS.some(term => lowerUrl.includes(term))) {
            reviews.push({ url, domain });
          }
        } catch (error) {
          console.warn('Error processing URL:', url, error);
        }
      });

      return { shopping, reviews };
    },
    formatDomain(url) {
      try {
        return new URL(url).hostname.replace('www.', '');
      } catch (e) {
        return url;
      }
    },
    handleCombinedClick(event) {
      // First check if it's a metadata block click.
      const metadataBlock = event.target.closest('.metadata-block');
      if (metadataBlock && metadataBlock.dataset.metadata) {
        try {
          const metadata = JSON.parse(metadataBlock.dataset.metadata);
          let type;
          let id;

          switch(metadata.type) {
            case 'file':
              type = 'FileSubmission';
              id = metadata.id;
              break;
            case 'text':
              type = 'TextSubmission';
              id = metadata.id;
              break;
            case 'url':
              type = 'ChatMessage';
              id = metadata.id || metadata.source;
              break;
            case 'youtube':
              type = 'YouTubeTranscriptSubmission';
              id = metadata.id;
              break;
            case 'ecrag':
              type = 'ChatMessage';
              id = metadata.id;
              break;
            default:
              console.warn('Unknown metadata type:', metadata.type);
              return;
          }

          this.$store.dispatch('websocket/setActiveObject', {
            type,
            id,
            rag_strategy: metadata.rag_strategy || 'llm_passthrough'
          });

          // Don't proceed to scrollToNextHighlight if we handled a metadata click
          return;
        } catch (error) {
          console.error('Error handling metadata click:', error);
        }
      }

      // If not a metadata click, proceed with highlight scrolling
      this.scrollToNextHighlight(event);
    },
    setupClickHandlers() {
    if (this.$refs.ragContentText) {
        // Remove existing handlers first to prevent duplicates
        this.$refs.ragContentText.removeEventListener('click', this.handleCombinedClick);
        this.$refs.ragContentText.addEventListener('click', this.handleCombinedClick);
      }
    },
    handleToggle() {
      this.isExpanded = !this.isExpanded;
    },
    clearCitationHighlights() {
      const contentElement = this.$refs.ragContentText;
      if (!contentElement) return;

      const highlights = contentElement.querySelectorAll('.citation-highlight');
      highlights.forEach(highlight => {
        const parent = highlight.parentNode;
        if (parent) {
          parent.replaceChild(
            document.createTextNode(highlight.textContent),
            highlight
          );
        }
      });
    },
    getHighlightTypeLabel() {
      switch(this.currentHighlightType) {
        case 'relevantSection':
          return 'Relevant';
        case 'userQuery':
          return 'Query';
        case 'search':
          return 'Search';
        case 'selected':
          return 'Selected';
        default:
          return '';
      }
    },
    analyzeContent() {
      if (!this.effectiveRagContent || !this.latestUserInput) return;

      // Split content into paragraphs/sections
      const sections = this.parsedRagContent
        .split(/\n\n+/)
        .filter(section => section.trim().length > 0);

      // Get all relevant sections first
      const relevantSections = sections
        .map(section => ({
          text: section,
          score: this.calculateRelevance(section, this.latestUserInput),
          index: this.effectiveRagContent.indexOf(section)
        }))
        .filter(section => section.score > MINIMUM_RELEVANCE_THRESHOLD)
        .sort((a, b) => b.score - a.score);

      // Take up to MAX_RELEVANT_HIGHLIGHTS
      this.relevantSections = relevantSections.length > MAX_RELEVANT_HIGHLIGHTS ?
        relevantSections.slice(0, MAX_RELEVANT_HIGHLIGHTS) :
        relevantSections;

      // Update highlight count
      this.highlightCountRelevant = this.relevantSections.length;

      this.currentHighlightType = 'relevantSection';
      this.autoScrollSettings.currentType = 'relevantSection';

      if (this.relevantSections.length > 0) {
        this.startAutoScroll('relevantSection');
      }
    },
    calculateRelevance(section, query) {
      // Updated weights with higher semantic emphasis
      const weights = {
        keywordMatch: 0.30,
        semanticSimilarity: 0.35,
        proximity: 0.1,
        keywordDensity: 0.1,
        position: 0.1,
        sectionLength: 0.05
      };

      let score = 0;
      const sectionLower = section.toLowerCase();
      const queryLower = query.toLowerCase();

      // 1. Keyword Matching (25%)
      const keywords = this.getKeywordsFromQuery(queryLower);
      const keywordScores = keywords.map(keyword => {
        const regex = new RegExp(this.escapeRegExp(keyword), 'gi');
        const matches = (sectionLower.match(regex) || []).length;
        return matches > 0 ? (matches * weights.keywordMatch) : 0;
      });
      score += Math.min(1, keywordScores.reduce((a, b) => a + b, 0));

      // 2. Semantic Similarity (25%) - Now with higher weight
      const semanticScore = this.calculateSemanticScore(section, query);
      score += semanticScore * weights.semanticSimilarity;

      // 3. Keyword Proximity (20%) - Same logic as before
      if (keywords.length > 1) {
        let proximityScore = 0;
        for (let i = 0; i < keywords.length - 1; i++) {
          for (let j = i + 1; j < keywords.length; j++) {
            const firstIndex = sectionLower.indexOf(keywords[i]);
            const secondIndex = sectionLower.indexOf(keywords[j]);
            if (firstIndex >= 0 && secondIndex >= 0) {
              const distance = Math.abs(secondIndex - firstIndex);
              proximityScore += 1 / (1 + distance / 30);
            }
          }
        }
        score += (proximityScore / (keywords.length * (keywords.length - 1) / 2)) * weights.proximity;
      }

      // 4. Keyword Density (15%) - Same as before
      const wordCount = sectionLower.split(/\s+/).length;
      const keywordDensity = keywordScores.length > 0 ?
        keywordScores.reduce((a, b) => a + b, 0) / wordCount : 0;
      score += keywordDensity * weights.keywordDensity;

      // 5. Position (10%) - Reduced importance
      const positionInDocument = this.effectiveRagContent.indexOf(section);
      const positionScore = 1 - (positionInDocument / this.effectiveRagContent.length);
      score += positionScore * weights.position;

      // 6. Section Length (5%) - Reduced importance
      const lengthScore = this.calculateLengthScore(section.length);
      score += lengthScore * weights.sectionLength;

      // Add bonus for multiple context matches
      const contextResult = this.checkContextWords(section, query);
      if (contextResult > 0.5) { // If significant context matches found
        score *= 1.2; // 20% bonus
      }

      return Math.min(1, score);
    },
    calculateLengthScore(length) {
      const idealMin = 100;
      const idealMax = 500;
      if (length < idealMin) {
        return length / idealMin;
      } else if (length > idealMax) {
        return idealMax / length;
      }
      return 1;
    },

    calculateSemanticScore(section, query) {
      let score = 0;
      const sectionLower = section.toLowerCase();

      // 1. Check for phrase matches
      const phrases = this.extractPhrases(query);
      phrases.forEach(phrase => {
        if (sectionLower.includes(phrase.toLowerCase())) {
          score += 0.5;
        }
      });

      // 2. Check for common synonyms
      const synonymMatches = this.checkSynonyms(section, query);
      score += synonymMatches * 0.3;

      // 3. Check for context words
      const contextScore = this.checkContextWords(section, query);
      score += contextScore * 0.2;

      return Math.min(1, score);
    },

    extractPhrases(text) {
      // Extract 2-3 word phrases
      const words = text.split(/\s+/);
      const phrases = [];

      for (let i = 0; i < words.length - 1; i++) {
        phrases.push(words[i] + ' ' + words[i + 1]);
        if (i < words.length - 2) {
          phrases.push(words[i] + ' ' + words[i + 1] + ' ' + words[i + 2]);
        }
      }

      return phrases;
    },

    checkSynonyms(section, query) {
      // Simple synonym dictionary
      const synonyms = SYNONYMS

      let matches = 0;
      const queryWords = query.toLowerCase().split(/\s+/);

      queryWords.forEach(word => {
        if (synonyms[word]) {
          synonyms[word].forEach(synonym => {
            if (section.toLowerCase().includes(synonym)) {
              matches++;
            }
          });
        }
      });

      return matches;
    },

    checkContextWords(section, query) {
      // Define context words for common topics
      const contextMap = CONTEXT_MAP;

      let score = 0;
      const queryWords = query.toLowerCase().split(/\s+/);
      const sectionLower = section.toLowerCase();

      queryWords.forEach(word => {
        if (contextMap[word]) {
          contextMap[word].forEach(contextWord => {
            if (sectionLower.includes(contextWord)) {
              score += 0.2;
            }
          });
        }
      });

      return Math.min(1, score);
    },

    autoScrollThroughSelectedText() {
      const contentElement = this.$refs.ragContentText;
      if (!contentElement) return;

      const highlights = contentElement.querySelectorAll('.highlight-selected');
      // Use the slider value for delay
      const delay = this.localAutoScrollDelay;

      contentElement.querySelectorAll('.highlight-current').forEach(el => {
        el.classList.remove('highlight-current');
      });

      const scrollToHighlight = (index) => {
        if (!this.selectedText || index >= highlights.length) {
          this.highlightIndex = 0;
          return;
        }

        this.highlightIndex = index;
        const highlight = highlights[index];
        const containerRect = contentElement.getBoundingClientRect();
        const highlightRect = highlight.getBoundingClientRect();

        let scrollOffset = contentElement.scrollTop +
          (highlightRect.top - containerRect.top) -
          (containerRect.height * 0.3);

        const maxScroll = contentElement.scrollHeight - contentElement.clientHeight;
        scrollOffset = Math.min(scrollOffset, maxScroll);
        scrollOffset = Math.max(0, scrollOffset);

        contentElement.scrollTo({
          top: scrollOffset,
          behavior: 'smooth'
        });

        highlights.forEach(el => el.classList.remove('highlight-current'));
        highlight.classList.add('highlight-current');

        if (index < highlights.length - 1) {
          this.currentScrollTimeout = setTimeout(() => {
            scrollToHighlight(index + 1);
          }, delay);
        }
      };

      scrollToHighlight(0);
    },

    handleTextSelection() {
      try {
        const selection = window.getSelection();
        const selectedText = selection?.toString()?.trim();

        if (!selectedText) return;

        // Emit both events - one for selected text tracking, one for chat input
        this.$emit('update:selected-text', selectedText);
        this.$emit('update-chat-input', selectedText);

        // Automatically switch to selected text highlights and start cycling
        this.currentHighlightType = 'selected';
        this.highlightIndex = 0;

        this.$nextTick(() => {
          this.updateHighlightCount();
          if (this.highlightCountSelected > 0) {
            this.autoScrollThroughSelectedText(); // Use auto-scroll instead of single scroll
          }
        });
        // Start auto-scrolling if there are selected text highlights
        if (this.highlightCountSelected > 0) {
          this.startAutoScroll('selected');
        }
      } catch (error) {
        console.warn('Error handling text selection:', error);
      }
    },

    // Add this to your existing template's div
    setupSelectionListener() {
      if (this.$refs.ragTokensContainer) {
        this.$refs.ragTokensContainer.addEventListener('mouseup', this.handleTextSelection);
        this.$refs.ragTokensContainer.addEventListener('touchend', this.handleTextSelection);
      }
    },

    cleanupSelectionListener() {
      if (this.$refs.ragTokensContainer) {
        this.$refs.ragTokensContainer.removeEventListener('mouseup', this.handleTextSelection);
        this.$refs.ragTokensContainer.removeEventListener('touchend', this.handleTextSelection);
      }
    },
    updateLocalData() {
      this.localRagTokens = this.ragTokens;
      this.localRagContent = this.activeObjectContent;
      this.localRagType = this.ragType;
      this.updateTokenDisplay(this.localRagTokens);
      this.showRagContent = true;
      this.updateRagContentVisibility(true);
    },
    showUserQueryHighlightWarning() {
      this.showTempMessageFn({
        message: `User query highlighting is disabled for large documents to maintain performance.`,
        type: 'warning',
        timeout: 5000,
      });
    },

    // Helper method to find next available highlight type
    findNextAvailableHighlightType(currentType) {
      const types = [
        { type: 'relevantSection', condition: () => this.relevantSections.length > 0 && this.showRelevantHighlights },
        { type: 'userQuery', condition: () => this.highlightCountUserQuery > 0 && this.showUserQueryHighlights },
        { type: 'search', condition: () => this.highlightCountSearch > 0 },
        { type: 'selected', condition: () => this.highlightCountSelected > 0 }
      ];

      // Find the index of current type
      const currentIndex = types.findIndex(t => t.type === currentType);

      // Check all other types in order
      for (let i = 1; i <= types.length; i++) {
        const index = (currentIndex + i) % types.length;
        if (types[index].condition()) {
          return types[index].type;
        }
      }

      return null;
    },
    nextHighlight() {
      const count = this.getCurrentHighlightCount();
      if (count > 0) {
        this.highlightIndex = (this.highlightIndex + 1) % count;
        this.scrollToHighlightByIndex();
      } else {
        // If current type has no highlights, try switching to next type
        const nextType = this.findNextAvailableHighlightType(this.currentHighlightType);
        if (nextType) {
          this.currentHighlightType = nextType;
          this.highlightIndex = 0;
          this.scrollToHighlightByIndex();
        }
      }
    },
    previousHighlight() {
      const count = this.getCurrentHighlightCount();
      if (count > 0) {
        this.highlightIndex = (this.highlightIndex - 1 + count) % count;
        this.scrollToHighlightByIndex();
      } else {
        // If current type has no highlights, try switching to next type
        const nextType = this.findNextAvailableHighlightType(this.currentHighlightType);
        if (nextType) {
          this.currentHighlightType = nextType;
          this.highlightIndex = 0;
          this.scrollToHighlightByIndex();
        }
      }
    },
    getCurrentHighlightCount() {
      if (!this.$refs.ragContentText) return 0;

      switch(this.currentHighlightType) {
        case 'search':
          return this.highlightCountSearch;
        case 'selected':
          return this.highlightCountSelected;
        case 'userQuery':
          return this.highlightCountUserQuery;
        case 'relevantSection':
          return this.relevantSections.length; // Use array length directly
        default:
          return Math.max(
            this.highlightCountSearch,
            this.highlightCountSelected,
            this.highlightCountUserQuery,
            this.relevantSections.length  // Include relevant sections in max count
          );
      }
    },
    scrollToHighlightByIndex() {
      // Early return if ref doesn't exist
      if (!this.$refs.ragContentText) return;

      const contentElement = this.$refs.ragContentText;
      const highlightClass = this.getHighlightClassFromType(this.currentHighlightType);

      // Add defensive check for highlightClass
      if (!highlightClass) {
        console.warn('Invalid highlight class:', highlightClass);
        return;
      }

      try {
        const highlights = contentElement.querySelectorAll(`.${highlightClass}`);

        if (this.highlightIndex >= 0 && this.highlightIndex < highlights.length) {
          const targetHighlight = highlights[this.highlightIndex];
          this.smoothScrollToHighlight(targetHighlight);
          this.updateHighlightStyle(targetHighlight);
        }
      } catch (error) {
        console.error('Error in scrollToHighlightByIndex:', error);
      }
    },
    smoothScrollToHighlight(element) {
      if (!element || !this.$refs.ragContentText) return;

      const contentElement = this.$refs.ragContentText;
      const containerRect = contentElement.getBoundingClientRect();
      const highlightRect = element.getBoundingClientRect();

      const idealPosition = contentElement.scrollTop +
        (highlightRect.top - containerRect.top) -
        (containerRect.height * this.scrollSettings.SCROLL_POSITION);

      const scrollPosition = Math.max(
        this.scrollSettings.SCROLL_PADDING,
        Math.min(
          idealPosition,
          contentElement.scrollHeight - containerRect.height - this.scrollSettings.SCROLL_PADDING
        )
      );

      const start = contentElement.scrollTop;
      const change = scrollPosition - start;
      const startTime = performance.now();

      const animateScroll = (currentTime) => {
        const elapsed = currentTime - startTime;
        const duration = this.scrollSettings.SCROLL_DURATION;
        let progress = Math.min(elapsed / duration, 1);

        // Smooth easing function
        const easeProgress = progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

        contentElement.scrollTop = start + change * easeProgress;

        if (elapsed < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          this.updateHighlightStyle(element);
        }
      };

      requestAnimationFrame(animateScroll);
    },
    updateHighlightStyle(targetElement) {
      const contentElement = this.$refs.ragContentText;

      // Clear previous highlights
      contentElement.querySelectorAll('.highlight-current').forEach(el => {
        el.classList.remove('highlight-current', 'highlight-pulse');
      });

      // Add new highlight with animation
      if (targetElement) {
        targetElement.classList.add('highlight-current');
        setTimeout(() => {
          targetElement.classList.add('highlight-pulse');
        }, 50);
      }
    },
    getHighlightClassFromType(type) {
      switch(type) {
        case 'search': return 'highlight-search';
        case 'selected': return 'highlight-selected';
        case 'userQuery': return 'highlight-user-query';
        case 'relevantSection': return 'highlight-relevant-section';
        default: return '';
      }
    },
    updateCurrentHighlight(element) {
      if (!element) return;
      const contentElement = this.$refs.ragContentText;
      contentElement.querySelectorAll('.highlight-current').forEach(el => el.classList.remove('highlight-current'));
      element.classList.add('highlight-current');
    },
    updateHighlightCount() {
      const contentElement = this.$refs.ragContentText;
      if (!contentElement) return;

      this.highlightCountUserQuery = contentElement.querySelectorAll('.highlight-user-query').length;
      this.highlightCountSearch = contentElement.querySelectorAll('.highlight-search').length;
      this.highlightCountSelected = contentElement.querySelectorAll('.highlight-selected').length;
      this.highlightCountRelevant = this.relevantSections.length;

      // Set the current highlight type if not set
      if (!this.currentHighlightType) {
        if (this.relevantSections.length > 0 ) {
          this.currentHighlightType = 'relevantSection';
        } else if (this.highlightCountUserQuery > 0) {
          this.currentHighlightType = 'userQuery';
        } else if (this.highlightCountSearch > 0) {
          this.currentHighlightType = 'search';
        } else if (this.highlightCountSelected > 0) {
          this.currentHighlightType = 'selected';
        }
      }

      // Update chip count display
      if (this.currentHighlightType === 'relevantSection') {
        this.highlightIndex = Math.min(this.highlightIndex, this.relevantSections.length - 1);
      }
    },
    updateHighlightWarnings() {
      const totalHighlights = this.highlightCountUserQuery + this.highlightCountSearch + this.highlightCountSelected;
      if (totalHighlights > 1000) {  // Adjust this threshold as needed
        this.showTempMessageFn({
          message: `Large number of highlights (${totalHighlights}) may affect performance.`,
          type: 'info',
          timeout: 5000,
        });
      }
    },
    resetHighlightCount() {
      this.highlightCountSearch = 0;
      this.highlightCountSelected = 0;
      this.highlightCountUserQuery = 0;
      this.highlightCountRelevant = 0;
      this.currentHighlightType = null;
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.showRagContent = false;
        this.$emit('update:showRagContent', false);
        this.updateRagContentVisibility(false);
      } else {
        this.showRagContent = true;
        this.$emit('update:showRagContent', true);
        this.updateRagContentVisibility(true);
      }
    },
    toggleRagContent() {
      if (this.showRagContent) {
        this.showRagContent = false;
        this.isExpanded = false;
        this.$emit('update:showRagContent', false);
        this.updateRagContentVisibility(false);
        return;
      }

      // Pro user validation code...
      this.showRagContent = true;
      this.isExpanded = true;
      this.$emit('update:showRagContent', true);
      this.updateRagContentVisibility(true);

      this.$nextTick(async () => {
        try {
          if (this.effectiveRagContent && this.latestUserInput && !this.isSearchDisabled) {
            await this.analyzeContent();
            // Auto-scroll will be triggered from within analyzeContent
          }
        } catch (error) {
          console.warn('Error analyzing content:', error);
        }
      });
    },
    updateRagContentVisibility(isVisible) {
      this.$nextTick(() => {
        const messagesContainer = document.querySelector('.messages-container');
        const ragTokensContainer = document.querySelector('.rag-tokens-container');
        const chatInputWrapper = document.querySelector('.chat-input-wrapper');

        if (isVisible) {
          messagesContainer.classList.add('rag-content-visible');
          ragTokensContainer.classList.add('expanded');
          chatInputWrapper.classList.add('rag-content-visible');
        } else {
          messagesContainer.classList.remove('rag-content-visible');
          ragTokensContainer.classList.remove('expanded');
          chatInputWrapper.classList.remove('rag-content-visible');
        }
      });
    },
    handleRagViewLimitResponse(data) {
      if (data.within_limit) {
        this.showRagContent = true;
        this.updateRagContentVisibility(true);
        this.scrollToAllHighlights();
      } else {
        this.showTempMessageFn({
          message: data.message,
          type: 'warning',
          timeout: 5000,
        });
      }
    },
    scrollToNextHighlight() {
      if (!this.autoScrollSettings.isActive) return;

      const contentElement = this.$refs.ragContentText;
      if (!contentElement) return;

      const highlightClass = this.getHighlightClassFromType(this.autoScrollSettings.currentType);
      const highlights = contentElement.querySelectorAll(`.${highlightClass}`);

      // Update highlight index
      if (this.highlightIndex >= highlights.length) {
        this.highlightIndex = 0;
      }

      const currentHighlight = highlights[this.highlightIndex];

      // Scroll to the current highlight
      this.smoothScrollToHighlight(currentHighlight);

      // Store current position before scheduling next scroll
      this.autoScrollSettings.lastPosition = this.highlightIndex;

      // Schedule next scroll
      this.autoScrollSettings.timeoutId = setTimeout(() => {
        this.highlightIndex++;
        this.scrollToNextHighlight();
      }, this.autoScrollSettings.delay);
    },

    scrollToHighlight(highlightClass) {
      if (!this.$refs.ragContentText) return;
      const contentElement = this.$refs.ragContentText;
      const highlightElements = contentElement.getElementsByClassName(highlightClass);

      let count;
      switch(highlightClass) {
        case 'highlight-search':
          count = this.highlightCountSearch;
          this.currentHighlightType = 'search';
          break;
        case 'highlight-selected':
          count = this.highlightCountSelected;
          this.currentHighlightType = 'selected';
          break;
        case 'highlight-user-query':
          count = this.highlightCountUserQuery;
          this.currentHighlightType = 'userQuery';
          break;
        case 'highlight-relevant-section':
          count = this.relevantSections.length;
          this.currentHighlightType = 'relevantSection';
          break;
        default:
          count = 0;
      }

      if (count > 0) {
        // If highlightIndex is -1 or out of bounds, reset it to 0
        if (this.highlightIndex < 0 || this.highlightIndex >= count) {
          this.highlightIndex = 0;
        }

        const targetHighlight = highlightElements[this.highlightIndex];
        const containerRect = contentElement.getBoundingClientRect();
        const highlightRect = targetHighlight.getBoundingClientRect();

        // Calculate scroll position
        let scrollOffset = contentElement.scrollTop + (highlightRect.top - containerRect.top) - 50;

        // Ensure we don't scroll past the content
        const maxScroll = contentElement.scrollHeight - contentElement.clientHeight;
        scrollOffset = Math.min(scrollOffset, maxScroll);

        // Smooth scroll
        contentElement.scrollTo({
          top: scrollOffset,
          behavior: 'smooth'
        });

        // Remove 'current' class from all highlights
        contentElement.querySelectorAll('.highlight-current').forEach(el => el.classList.remove('highlight-current'));

        // Add 'current' class to the current highlight
        targetHighlight.classList.add('highlight-current');

      }
    },
    scrollToAllHighlights() {
      this.$nextTick(() => {
        this.scrollToHighlight('highlight-user-query');
        this.scrollToHighlight('highlight-search');
        this.scrollToHighlight('highlight-selected');
        this.scrollToHighlight('highlight-relevant-section');
      });
    },

    updateTokenDisplay(value) {
      try {
        // Ensure value is a number
        const numericValue = Number(value) || 0;

        // Convert to string, pad, and split into array
        const paddedValue = Math.max(0, Math.floor(numericValue))
          .toString()
          .padStart(5, '0');

        // Safely create digits array
        const newDigits = paddedValue
          .slice(0, 7)  // Ensure we only take first 7 digits
          .split('')
          .map(digit => Number(digit) || 0);  // Convert to numbers, default to 0

        // Ensure array is exactly 7 digits
        while (newDigits.length < 7) {
          newDigits.unshift(0);
        }

        // Update state
        this.ragTokenDigits = newDigits;

        // Only animate if we have valid digits
        if (newDigits.every(digit => typeof digit === 'number')) {
          this.animateTokens(newDigits);
        }
      } catch (error) {
        console.warn('Error updating token display:', error);
        // Fallback to zeros if there's an error
        this.ragTokenDigits = [0, 0, 0, 0, 0];
      }
    },
    animateTokens() {
      const digitColumns = document.querySelectorAll('.rag-token-digit-column');
      const numberString = this.ragTokenDigits.join('');

      digitColumns.forEach((column, index) => {
        if (index < numberString.length) {
          const newValue = parseInt(numberString[index]);
          column.classList.add('rolling');
          column.style.transform = `translateY(-${newValue * 40}px)`;

          setTimeout(() => {
            column.classList.remove('rolling');
          }, 2000);
        } else {
          // Reset any extra digits to 0
          column.style.transform = 'translateY(0)';
        }
      });
    },
    handleSearchQuery() {
      try {
        // Clear existing timeout when search changes
        if (this.searchScrollTimeout) {
          clearTimeout(this.searchScrollTimeout);
          this.searchScrollTimeout = null;
        }

        // Guard clauses for null/undefined cases
        if (!this.searchQuery) {
          this.clearHighlights();
          return;
        }

        // Check if search is already in progress or unchanged
        if (this.isSearching || this.searchQuery === this.lastSearchQuery) {
          return;
        }

        // Store last query
        this.lastSearchQuery = this.searchQuery || '';

        // Check minimum length
        if (this.searchQuery.trim().length < 2) {
          this.clearHighlights();
          return;
        }

        // Set search flag
        this.isSearching = true;

        // Wrap in try-catch for animation frame
        this.$nextTick(() => {
          try {
            requestAnimationFrame(() => {
              try {
                this.performSearch();
              } catch (searchError) {
                console.warn('Error in performSearch:', searchError);
                this.isSearching = false;
              }
            });
          } catch (animationError) {
            console.warn('Error in requestAnimationFrame:', animationError);
            this.isSearching = false;
          }
        });
        // Start auto-scrolling if there are search results
        if (this.highlightCountSearch > 0) {
          this.startAutoScroll('search');
        }
      } catch (error) {
        console.warn('Error in handleSearchQuery:', error);
        this.isSearching = false;

        this.clearHighlights();
      }
    },
    performSearch() {
      const start = performance.now();
      this.updateHighlightCount();
      this.autoScrollSettings.currentType = 'search';
      this.currentHighlightType = 'search';
      this.highlightControls.showSearch = true;

      const end = performance.now();
      this.isSearching = false;

      if (end - start > 1000) {
        this.showPerformanceWarning();
      }
    },
    showPerformanceWarning() {
      this.showTempMessageFn({
        message: "Search is taking longer than expected. Consider refining your search query.",
        type: 'warning',
        timeout: 5000,
      });
    },
    formatUrl(url) {
      try {
        const urlObj = new URL(url);
        // Get domain and first part of path
        const domain = urlObj.hostname;
        const path = urlObj.pathname;

        if (path.length > 30) {
          // Show domain + first 30 chars of path + ...
          return `${domain}${path.substring(0, 30)}...`;
        }

        if (url.length > 50) {
          // If full URL is too long, trim it
          return `${url.substring(0, 50)}...`;
        }

        return url;
      } catch (e) {
        console.warn('Invalid URL:', e);
        return url;
      }
    },
    handleContentClick(event) {
      const metadataBlock = event.target.closest('.metadata-block');
      if (!metadataBlock || !metadataBlock.dataset.metadata) return;

      try {
        const metadata = JSON.parse(metadataBlock.dataset.metadata);
        let type;
        let id;

        switch(metadata.type) {
          case 'file':
            type = 'FileSubmission';
            id = metadata.id;
            break;
          case 'text':
            type = 'TextSubmission';
            id = metadata.id;
            break;
          case 'url':
            type = 'ChatMessage';
            id = metadata.id || metadata.source;
            break;
          case 'youtube':
            type = 'YouTubeTranscriptSubmission';
            id = metadata.id;
            break;
          case 'ecrag':
            type = 'ChatMessage';
            id = metadata.id;
            break;
          default:
            console.warn('Unknown metadata type:', metadata.type);
            return;
        }

        // Set active object which will trigger content fetch
        this.$store.dispatch('websocket/setActiveObject', {
          type,
          id,
        });
      } catch (error) {
        console.error('Error handling metadata click:', error);
      }
    },

    replaceURLsAndEntities(text) {
      if (!text) return text;

       // Create a map of URLs to their thumbnails from search results
      const urlThumbnails = new Map();
      this.searchResults?.forEach(result => {
        if (result.url && (result.thumbnail || result.image)) {
          urlThumbnails.set(result.url, result.thumbnail || result.image);
        }
      });

      // replace all markers like SEARCH_START, END_OF_CONTENT
      text = text.replace(/\[SEARCH_START\]/g, '');
      text = text.replace(/\[END_OF_CONTENT\]/g, '');

      // Extract prices first
      const prices = this.extractPrices(text);

      // Create price chips HTML if we found prices
      let priceChipsHtml = '';
      if (prices.length > 0) {
        priceChipsHtml = `
          <div class="price-chips">
            ${prices.map(price => `
              <span class="price-chip">
                <svg class="price-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <circle cx="12" cy="12" r="10"/>
                  <path d="M12 7v10M9 10h6"/>
                </svg>
                ${price}
              </span>
            `).join('')}
          </div>
        `;
      }

      // First find all URLs and their positions
      const urlPositions = new Map();
      let urlMatch;
      const urlRegex = /\[URL:\s*(https?:\/\/[^\]]+)\]/g;
      while ((urlMatch = urlRegex.exec(text)) !== null) {
        urlPositions.set(urlMatch.index, urlMatch[1]);
      }

      // Convert to sorted array of [position, url]
      const sortedUrls = Array.from(urlPositions.entries()).sort((a, b) => a[0] - b[0]);

      // Replace citations with their corresponding URLs
      text = text.replace(/\[c(\d+)\]/g, (match, num, offset) => {
        // Find the last URL before this citation
        const url = sortedUrls.reduce((acc, [pos, url]) => {
          if (pos < offset) return url;
          return acc;
        }, '');

        // Extract text around citation for price scanning (e.g., previous 100 chars)
        const surroundingText = text.slice(Math.max(0, offset - 200), offset + 200);
        const prices = this.extractPrices(surroundingText);

        // Extract HREFs
        const hrefRegex = /\[HREF:\s*([^|]+)\|(https?:\/\/[^\]]+)\]/g;
        const hrefs = [...surroundingText.matchAll(hrefRegex)].map(match => ({
          text: match[1],
          url: match[2]
        }));

        // Create price chips HTML if we found prices
        const priceChipsHtml = prices.length ? `
          <span class="price-chips">
            ${prices.map(price => `
              <span class="price-chip">
                <svg class="price-icon" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <circle cx="12" cy="12" r="10"/>
                  <path d="M12 7v10M9 10h6"/>
                </svg>
                ${price}
              </span>
            `).join('')}
          </span>
        ` : '';

        // Add HREF chips
        const hrefChipsHtml = hrefs.length ? `
          <span class="href-chips">
            ${hrefs.map(href => `
              <a href="${href.url}"
                target="_blank"
                rel="noopener noreferrer"
                class="href-chip"
                title="${href.url}">
                <svg class="href-icon" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/>
                  <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/>
                </svg>
                ${href.text}
              </a>
            `).join('')}
          </span>
        ` : '';

        const sourceHtml = this.showRagContent && url ?
          `<a href="${url}" target="_blank" rel="noopener noreferrer" class="citation-source" title="${url}">${this.formatUrl(url)}</a>` : '';

        // Store product names to prevent duplication
        const productNames = new Set();
        hrefs.forEach(match => productNames.add(match.text));
        prices.forEach(price => {
          const priceMatch = price.match(/\$\d+/);
          if (priceMatch) productNames.add(price);
        });

        // Remove extra spaces
        text = text.trim();

        return `<span class="citation-wrapper">
          ${priceChipsHtml}
          ${hrefChipsHtml}
          <span class="inline-citation">c${num}</span>
          ${sourceHtml}
        </span>`;
      });

      // remove HREFs from text
      text = text.replace(/\[HREF:\s*([^|]+)\|(https?:\/\/[^\]]+)\]/g, '');

      // When replacing URLs, check if we have a thumbnail
      text = text.replace(/(\[URL:\s*(https?:\/\/[^\]]+)\])/g, (match, fullText, url) => {
        const thumbnail = urlThumbnails.get(url);
        const thumbnailHtml = thumbnail ? `
          <div class="url-thumbnail">
            <img src="${thumbnail}"
                alt="Preview"
                onerror="this.style.display='none'"
                class="url-preview-img" />
          </div>
        ` : '';

        return `
          <div class="url-wrapper">
            ${thumbnailHtml}
            <a href="${url}"
              target="_blank"
              rel="noopener noreferrer"
              class="inline-url"
              title="Visit ${url}">
              ${fullText}
            </a>
          </div>
        `;
      });

      // First handle Title and Description patterns without touching URLs
      text = text.replace(/\[Title:\s*([^\]]+)\]\s*\[Description:\s*([^\]]+)\]/g,
        (match, title, description) => {
          return `<div class="url-section">
            <div class="url-title">${title.trim()}</div>
            <div class="url-description">${description.trim()}</div>
          </div>`;
      });

      // Handle citations separately - keeping URLs intact
      text = text.replace(/\[c(\d+)\]/g, (match, num, offset) => {
        // Process citation wrapper without modifying URLs
        const sourceHtml = this.showRagContent ?
          `<a href="${sortedUrls[0]?.[1] || ''}" target="_blank" rel="noopener noreferrer" class="citation-source" title="${sortedUrls[0]?.[1] || ''}">${this.formatUrl(sortedUrls[0]?.[1] || '')}</a>` : '';

        return `<span class="citation-wrapper">
          <span class="inline-citation">c${num}</span>
          ${sourceHtml}
        </span>`;
      });

      // Handle entity replacements
      text = text.replace(/# (eid-\d+)__(eoid-\d+)/g, (match, eid, eoid) => {
        const basePath = 'https://ecsp-sch.unstruct.ai/kjalleda_55002_uni3_universe';
        const entityNum = eid.split('-')[1];
        const adosUrl = `${basePath}/ados?q=eid:${entityNum}`;

        return `<a href="${adosUrl}" target="_blank" rel="noopener noreferrer" class="inline-entity">${eoid}</a>`;
      });

      // First handle citations within highlights
      text = text.replace(/\[c(\d+)\]/g, (match, num) => {
        return `<span class="inline-citation">c${num}</span>`;
      });

      // Match the complete metadata block including nested braces
      const findMetadata = /\[META:\s*({(?:[^{}]|{[^{}]*})*})\]/g;

      // Replace each metadata block found
      return text.replace(findMetadata, (fullMatch, jsonPart) => {
        if (!jsonPart) return fullMatch; // Return original if no JSON part found

        try {
          const metadata = JSON.parse(jsonPart);

          // Common attributes for all metadata blocks
          const commonAttrs = `
            role="button"
            tabindex="0"
            class="metadata-block"
          `;

          let replacement = '';

          // Special handling for eccs_project type
          if (metadata.type === 'eccs_project') {
            const fileList = metadata.files.map(file => {
              const fileData = {
                type: file.type,
                id: file.id,
                fileName: file.fileName,
                fileSize: file.fileSize,
                fileType: file.fileType
              };

              return `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(fileData)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <path d="M4 6a2 2 0 0 1 2-2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z" />
                      <path d="M14 4v6h6" />
                    </svg>
                    <span class="file-number">#${file.id}</span>
                    <span class="file-name">${file.fileName}</span>
                    <span class="file-size">${(file.fileSize / 1024).toFixed(1)}KB</span>
                  </div>
                  <span class="file-type">${file.fileType}</span>
                </div>`;
            }).join('\n');

          replacement = `
            <div class="eccs-project-container">
              <div class="eccs-project-header">
                <svg class="source-icon" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                  <path d="M3 3h18v18H3z"/>
                  <path d="M8 12h8M12 8v8"/>
                </svg>
                <span>Project #${metadata.id} (${metadata.files.length} files)</span>
              </div>
              <div class="eccs-files-list">
                ${fileList}
              </div>
            </div>`;
        } else {
          switch (metadata.type) {
            case 'file': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify({
                      type: 'file',
                      id: metadata.id,
                      fileName: metadata.fileName
                    })}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <path d="M4 6a2 2 0 0 1 2-2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z" />
                      <path d="M14 4v6h6" />
                    </svg>
                    <span class="file-number">#${metadata.id}</span>
                    <span class="file-name">${metadata.fileName}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'url': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <path d="M12 4L4 8v8l8 4l8-4V8L12 4z" />
                      <path d="M12 12l8-4M12 12v8M4 8l8 4" />
                    </svg>
                    <span class="trimmed-url">${this.formatUrl(metadata.source)}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'text': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <rect x="4" y="4" width="16" height="16" rx="2" />
                      <path d="M8 8h8M8 12h8M8 16h4" />
                    </svg>
                    <span class="text-id">Text #${metadata.id}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'youtube': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <path d="M12 8l6 4l-6 4V8z" />
                      <path d="M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z" />
                    </svg>
                    <span class="youtube-id">YouTube #${metadata.id}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'search': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                    <span class="search-query">Search: ${metadata.id}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            case 'ecrag': {
              replacement = `
                <div ${commonAttrs}
                    data-metadata='${JSON.stringify(metadata)}'>
                  <div class="metadata-main">
                    <svg class="source-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75">
                      <circle cx="12" cy="12" r="10" />
                      <path d="M16 12l-4 4l-4-4" />
                    </svg>
                    <span class="ecrag-id">ECRAG #${metadata.id}</span>
                  </div>
                  <span class="citation-range">Citations: ${metadata.citation_range || ''}</span>
                </div>`;
              break;
            }

            default:
              console.warn('Unknown metadata type:', metadata.type);
              return text;
          }
        }

        // Replace the entire metadata block with our generated HTML
        return replacement;

        } catch (e) {
          console.error('Error processing metadata:', e,
            '\nJSON string length:', jsonPart.length,
            '\nLast 50 characters:', jsonPart.slice(-50)
          );
          return fullMatch;
        }
      });
    },

    getSourceFromMetadata(metadata, citation) {
      try {
        const metadataObj = JSON.parse(metadata);
        const [start, end] = metadataObj.citation_range.split('-').map(Number);
        const citationNum = Number(citation);

        if (citationNum >= start && citationNum <= end) {
          switch (metadataObj.type) {
            case 'url':
              return new URL(metadataObj.source).hostname;
            case 'file': {
              const fileMatch = metadataObj.id.match(/(\d+)\s*File_Name:\s*(.+)/);
              return fileMatch ? `File #${fileMatch[1]}` : `File ${metadataObj.id}`;
            }
            case 'text':
              return `Text ${metadataObj.id}`;
            case 'youtube':
              return `YouTube ${metadataObj.id}`;
            default:
              return 'Unknown Source';
          }
        }
      } catch (e) {
        console.warn('Error parsing metadata:', e);
      }
      return 'Unknown Source';
    },
  }
}
</script>

<style scoped>
/* RAG Tokens Container - Modern Drawer Layout */
.rag-tokens-container {
  grid-column: 2;
  position: sticky;
  top: 60px;
  /* Increased width with wider boundaries */
  width: 62%;
  max-width: 1000px;
  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background: var(--surface-overlay);
  backdrop-filter: blur(400px);
  overflow-y: auto;
  z-index: 0;
  isolation: isolate;

  /* Custom scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: rgba(var(--v-theme-on-surface), 0.12) transparent;
  overscroll-behavior: none; /* Prevent rubber-banding on iOS */
}

.theme--dark .rag-tokens-container {
  border-right: 1px solid black;
}

/* RAG Tokens Container - Expanded State */
.rag-tokens-container.expanded {
  transform: translateX(0);
  visibility: visible; /* Show when expanded */
  z-index: 100; /* Higher z-index when expanded */
}

/* hide on mobile */
@media (max-width: 768px) {
  .rag-tokens-container,.rag-tokens-container.expanded {
    display: none;
  }
}

@media (max-width: 900px) {
  .rag-tokens-container,.rag-tokens-container.expanded {
    display: none;
  }
}

.toggle-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}


.rag-header.sticky {
  position: sticky;
  top: 0;
  gap: 1rem;
  z-index: 20;
  backdrop-filter: blur(16px);
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.theme--dark .rag-header.sticky {
  background: rgba(17, 24, 39, 0.8);
  border-bottom: 1px solid rgba(99, 102, 241, 0.15);
}

.content-sections {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.section {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  background: rgba(99, 102, 241, 0.03);
  border-radius: 12px;
  padding: 0.5rem;
  border: 1px solid rgba(99, 102, 241, 0.08);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.section.visible {
  opacity: 1;
  transform: translateY(0);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(99, 102, 241, 0.1);
}

.search-section {
  animation: slideDown 0.5s ease forwards;
}

.links-section {
  animation: slideDown 0.5s ease 0.2s forwards;
}

.content-section {
  animation: slideDown 0.5s ease 0.4s forwards;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .content-sections {
    padding: 0.75rem;
    gap: 1rem;
  }

  .section {
    padding: 1rem;
  }
}

.rag-tokens-title {
  font-weight: bold;
  font-size: 12px;
}

.rag-tokens-display-wrapper {
  position: relative;
  height: 40px;
  overflow: hidden;
}

.rag-tokens-display {
  display: flex;
  justify-content: center;
}

.rag-token-digit {
  position: relative;
  margin: 0 2px;
  width: 20px;
  height: 40px;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rag-token-digit-column {
position: absolute;
top: 0;
left: 0;
width: 100%;
transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);
display: flex;
flex-direction: column;
align-items: center;
}

.rag-token-digit-column span {
display: block;
align-items: center;
justify-content: center;
height: 40px;
line-height: 40px;
text-align: center;
width: 100%;
font-size: 18px;
}

.rolling {
transition: transform 2s cubic-bezier(0.23, 1, 0.32, 1);
}
.rag-content-container {
  border-radius: 4px;
  overflow: hidden;
}

.theme--dark .rag-tokens-container {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
}

.rag-content {
  padding: 0px 20px;
}

.rag-content-text {
  position: relative;
  transition: all 0.3s ease;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 0.875rem;
  line-height: 1.35rem;
  max-height: 93vh; /* DO NOT REMOVE */
  padding: 0px 40px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
}

.theme--dark .rag-content-text {
  background: linear-gradient(
    145deg,
    rgba(30, 41, 59, 0.95),
    rgba(15, 23, 42, 0.98)
  );
}
  /* For Webkit browsers (Chrome, Safari, newer versions of Edge) */
  ::-webkit-scrollbar {
    width: 8px;  /* Slightly narrower for subtlety */
  }

  ::-webkit-scrollbar-track {
    background: rgba(30, 30, 30, 0.5);  /* Dark, semi-transparent background */
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(80, 80, 80, 0.7);  /* Slightly lighter, more opaque thumb */
    border-radius: 4px;  /* Rounded corners for the thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(100, 100, 100, 0.8);  /* Lighter on hover for better visibility */
  }

  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(80, 80, 80, 0.7) rgba(30, 30, 30, 0.5);
  }

  /* For Internet Explorer and Edge (older versions) */
  body {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

.custom-hover {
position: absolute;
top: 0;
left: 0;
z-index: 1;
transition: all 0.3s ease;
}

.custom-hover::before {
content: '';
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(128, 128, 128, 0.2);
opacity: 0;
transition: opacity 0.3s ease;
border-radius: inherit;
}

.custom-hover:hover::before {
opacity: 1;
}

.custom-hover:hover {
transform: scale(1.1);
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-hover:hover .v-icon {
color: #004bfb !important;
}

.custom-hover .v-icon {
position: relative;
z-index: 1;
}
.custom-hover .v-badge__badge {
z-index: 2;
}

.custom-hover:hover .v-badge__badge {
transform: scale(0.91);  /* Counteract the button scaling */
}

/* Ensure proper alignment within the button */
.custom-hover.v-btn--icon {
display: flex;
align-items: center;
justify-content: center;
}

.custom-hover.v-btn--icon .v-icon {
margin: 0;
}

.theme--dark.v-card {
background-color: inherit !important;
}
/* Custom highlight colors with white text */
:root {
--highlight-primary-bg: rgba(59, 130, 246, 0.2);
--highlight-secondary-bg: rgba(167, 139, 250, 0.2);
--highlight-success-bg: rgba(34, 197, 94, 0.2);
--highlight-warning-bg: rgba(234, 179, 8, 0.2);
}

/* Common styles for all highlights */
:deep([class*="highlight-"]) {
  position: relative;
  border-radius: 2px;  /* Reduced from 4px */
  padding: 1rem;
  margin: 0;  /* Removed margins */
  font-weight: 500;
  font-weight: 500;
  background: rgba(167, 139, 250, 0.2);  /* Slightly stronger background */
  border-bottom: 1px solid rgba(167, 139, 250, 0.3);  /* Subtle underline */
  transition: all 0.3s ease;
  display: inline-block;
  line-height: inherit;  /* Changed from 1.4 */
  backdrop-filter: blur(4px);
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

/* Individual highlight styles */
:deep(.highlight-relevant-section) {
  background: rgba(167, 139, 250, 0.15);  /* Single color, reduced opacity */
  text-shadow: none;  /* Removed text shadow */
}

:deep(.highlight-relevant-section.highlight-current) {
  background: rgba(167, 139, 250, 0.25);  /* Slightly stronger */
  box-shadow: 0 0 0 1px rgba(167, 139, 250, 0.2);  /* Subtle border */
}

:deep(.highlight-user-query) {
background: linear-gradient(120deg,
  rgba(59, 130, 246, 0.25),
  rgba(59, 130, 246, 0.15)
);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

:deep(.highlight-search) {
background: linear-gradient(120deg,
  rgba(34, 197, 94, 0.25),
  rgba(34, 197, 94, 0.15)
);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}


/* Enhanced citation highlight style */
:deep(.citation-highlight) {
background: linear-gradient(120deg,
  rgba(59, 130, 246, 0.35),
  rgba(59, 130, 246, 0.25)
);
box-shadow:
  0 0 0 2px rgba(59, 130, 246, 0.2),
  0 4px 8px rgba(0, 0, 0, 0.1);
padding: 0.2em 0.5em;
margin: 0 0.2em;
border-radius: 4px;
font-weight: 500;
animation: citationEmphasis 0.4s cubic-bezier(0.4, 0, 0.2, 1);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
backdrop-filter: blur(8px);
}

@keyframes citationEmphasis {
0% {
  opacity: 0.6;
  transform: translateY(-4px);
  box-shadow:
    0 0 0 0 rgba(59, 130, 246, 0.4),
    0 0 0 rgba(0, 0, 0, 0);
}
100% {
  opacity: 1;
  transform: translateY(0);
  box-shadow:
    0 0 0 2px rgba(59, 130, 246, 0.2),
    0 4px 8px rgba(0, 0, 0, 0.1);
}
}

:deep(.metadata-block) {
display: flex;
justify-content: space-between;
align-items: center;
padding: 16px 20px;
margin: 12px 0;
border-radius: 12px;

/* Modern glassmorphism effect */
backdrop-filter: blur(12px);
-webkit-backdrop-filter: blur(12px);
background: linear-gradient(
  135deg,
  rgba(29, 78, 216, 0.15) 0%,
  rgba(30, 64, 175, 0.25) 50%,
  rgba(17, 24, 39, 0.35) 100%
);

/* Subtle border glow */
border: 1px solid rgba(147, 197, 253, 0.15);
box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.1),
            0 0 16px -2px rgba(147, 197, 253, 0.15);

/* Smooth transitions */
transition: all 0.3s ease-in-out;
}

/* Hover state for better interactivity */
:deep(.metadata-block):hover {
transform: translateY(-2px);
background: linear-gradient(
  135deg,
  rgba(29, 78, 216, 0.2) 0%,
  rgba(30, 64, 175, 0.3) 50%,
  rgba(17, 24, 39, 0.4) 100%
);
border-color: rgba(147, 197, 253, 0.25);
box-shadow: 0 8px 24px -6px rgba(0, 0, 0, 0.15),
            0 0 20px -4px rgba(147, 197, 253, 0.2);
}

:deep(.highlight-selected) {
background: rgba(167, 139, 250, 0.3);
box-shadow: 0 0 0 2px rgba(167, 139, 250, 0.4);
text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
animation: selectPulse 2s ease-in-out infinite;
}

:deep(.metadata-block) {
display: flex;
justify-content: space-between;
align-items: center;
padding: 16px 20px;
margin: 12px 0;
border-radius: 12px;

/* Modern glassmorphism with pink/rose tint */
backdrop-filter: blur(12px);
-webkit-backdrop-filter: blur(12px);
background: linear-gradient(
  135deg,
  rgba(236, 72, 153, 0.08) 0%,
  rgba(219, 39, 119, 0.12) 50%,
  rgba(190, 24, 93, 0.15) 100%
);

/* Subtle border glow matching the rose theme */
border: 1px solid rgba(236, 72, 153, 0.1);
box-shadow: 0 4px 20px -4px rgba(0, 0, 0, 0.1),
            0 0 16px -2px rgba(236, 72, 153, 0.1);

/* Smooth transitions */
transition: all 0.3s ease-in-out;
}

/* Hover state with enhanced glow */
:deep(.metadata-block):hover {
transform: translateY(-2px);
background: linear-gradient(
  135deg,
  rgba(236, 72, 153, 0.12) 0%,
  rgba(219, 39, 119, 0.16) 50%,
  rgba(190, 24, 93, 0.2) 100%
);
border-color: rgba(236, 72, 153, 0.15);
box-shadow: 0 8px 24px -6px rgba(0, 0, 0, 0.15),
            0 0 20px -4px rgba(236, 72, 153, 0.15);
}


/* Optional: Add animation for content */
:deep(.metadata-block) > * {
opacity: 0.95;
transition: opacity 0.2s ease;
}

:deep(.metadata-block):hover > * {
opacity: 1;
}

:deep(.metadata-main) {
display: flex;
align-items: center;
gap: 8px;
min-width: 0;
}

:deep(.file-prefix) {
opacity: 0.7;
}

:deep(.file-number) {
font-family: monospace;
padding: 2px 6px;
background: rgba(230, 240, 255, 0.1);
border-radius: 4px;
}

:deep(.file-name) {
font-weight: 500;
}

:deep(.citation-range) {
font-size: 0.9em;
opacity: 0.7;
padding: 2px 8px;
background: rgba(230, 240, 255, 0.1);
border-radius: 4px;
margin-left: 12px;
flex-shrink: 0; /* Prevent citation range from shrinking */
}

:deep(.metadata-block a) {
text-decoration: none;
}

:deep(.metadata-block a:hover) {
text-decoration: underline;
opacity: 0.8;
}

/* Light theme overrides */
:deep([class*='light'] .metadata-block) {
background: linear-gradient(
  to right,
  rgba(230, 240, 255, 0.05) 0%,
  rgba(230, 240, 255, 0.1) 50%,
  rgba(230, 240, 255, 0.05) 100%
);
}

:deep([class*='light'] .file-number),
:deep([class*='light'] .citation-range) {
background: rgba(230, 240, 255, 0.2);
}

:deep(.trimmed-url) {
display: inline-block;
max-width: 400px; /* Adjust based on your layout */
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
color: inherit;
text-decoration: none;
position: relative;
}

:deep(.trimmed-url:hover) {
text-decoration: underline;
}

:deep(.trimmed-url:hover::after) {
content: attr(title);
position: absolute;
top: -30px;
left: 50%;
transform: translateX(-50%);
background: rgba(0, 0, 0, 0.8);
color: white;
padding: 4px 8px;
border-radius: 4px;
font-size: 12px;
white-space: nowrap;
z-index: 1000;
pointer-events: none;
}

/* Add inline citation styling */
:deep(.inline-citation) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4em;
  height: 1.4em;
  font-size: 0.75rem;
  font-weight: 500;
  /* Softer blue background with better contrast */
  color: #f8fafc;
  background-color: #3b82f6;
  /* Add subtle gradient for depth */
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.1)
  );
  border-radius: 999px;
  padding: 0 0.4em;
  margin: 0 0.15em;
  text-decoration: none;
  transition: all 0.15s ease;
  position: relative;
  user-select: none;
  backdrop-filter: blur(4px);
  vertical-align: middle;
  margin-bottom: 10px;
  /* Add subtle shadow for depth */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

:deep(.inline-citation:hover) {
  /* Slightly lighter on hover for feedback */
  color: #ffffff;
  background-color: #2563eb;
  /* Add subtle lift effect */
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Dark theme adjustments */
:deep([class*='dark'] .inline-citation) {
  /* Darker, more muted blue for dark theme */
  color: #e2e8f0;
  background-color: rgba(37, 99, 235, 0.2);
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.05),
    rgba(0, 0, 0, 0.2)
  );
  /* Softer shadow for dark theme */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(255, 255, 255, 0.05);
}

:deep([class*='dark'] .inline-citation:hover) {
  color: #f8fafc;
  background-color: #2563eb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2),
              0 0 0 1px rgba(255, 255, 255, 0.1);
}

/* When citations appear within highlighted text */
:deep([class*="highlight-"] .inline-citation) {
  background-color: rgba(59, 130, 246, 0.9);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

:deep([class*="highlight-"] .inline-citation:hover) {
  background-color: rgba(37, 99, 235, 0.95);
}

/* Make citations look good in highlighted text */
:deep([class*="highlight-"] .inline-citation) {
background-color: rgba(255, 255, 255, 0.15);
border-color: rgba(255, 255, 255, 0.2);
margin: 0 0.2em;
}

:deep([class*="highlight-"] .inline-citation:hover) {
background-color: rgba(255, 255, 255, 0.25);
border-color: rgba(255, 255, 255, 0.3);
}

/* Make citations within highlights stand out */
:deep(.highlight-selected .inline-citation),
:deep(.highlight-current .inline-citation) {
background-color: rgba(255, 255, 255, 0.25);
border-color: rgba(255, 255, 255, 0.35);
transform: scale(1.1);
box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
}
/* Animations for better visual feedback */
@keyframes selectPulse {
  0%, 100% {
    box-shadow:
      0 0 0 2px rgba(251, 191, 36, 0.2),    /* Yellow pulse */
      0 0 20px rgba(251, 191, 36, 0.3);
  }
  50% {
    box-shadow:
      0 0 0 4px rgba(251, 191, 36, 0.3),
      0 0 20px rgba(251, 191, 36, 0.3);
  }
}

@keyframes currentPulse {
  0%, 100% {
    box-shadow:
      0 0 0 2px rgba(59, 130, 246, 0.3),    /* Blue pulse */
      0 4px 8px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow:
      0 0 0 4px rgba(59, 130, 246, 0.4),
      0 6px 12px rgba(0, 0, 0, 0.15);
  }
}

.metadata-block .source-icon {
flex-shrink: 0;
opacity: 0.75;
transition: all 0.3s ease;
}

.metadata-block .metadata-main {
display: flex;
align-items: center;
gap: 8px;
}

.metadata-block a:hover .source-icon,
.metadata-block .metadata-main:hover .source-icon {
opacity: 1;
transform: scale(1.1);
filter: drop-shadow(0 0 2px rgba(99, 102, 241, 0.3));
}

/* Style updates for different source types */
.metadata-block .file-number {
color: #004bfb;
font-weight: 500;
}

.metadata-block .file-name {
color: #4B5563;
}

.metadata-block .text-id,
.metadata-block .youtube-id {
color: #4B5563;
}

.metadata-block .trimmed-url {
  color: #004bfb;
  text-decoration: none;
  transition: color 0.2s ease;
}

.metadata-block .trimmed-url:hover {
  color: #4F46E5;
}

:deep(.highlight-semantic-match) {
  display: inline-block;
  position: relative;
  z-index: 1;
}

/* Add these to your CSS */
:deep(.highlight-relevant-very-high) {
  background-color: rgba(255, 193, 7, 0.25);
}

:deep(.highlight-relevant-high) {
  background-color: rgba(255, 193, 7, 0.2);
}

:deep(.highlight-relevant-medium) {
  background-color: rgba(25, 118, 210, 0.2);
}

:deep(.highlight-relevant-low) {
  background-color: rgba(25, 118, 210, 0.15);
}

/* Make sure this comes after the relevance classes to ensure proper specificity */
:deep(.highlight-semantic-match.highlight-current) {
  background: rgba(167, 139, 250, 0.25);
  box-shadow: 0 0 0 1px rgba(167, 139, 250, 0.2);
  animation: currentPulse 2s ease-in-out infinite;
  font-size: 1rem;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #4B5563;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
/* Add new styles for the badge */
:deep(.v-badge__badge) {
  font-size: 10px !important;
  height: 20px !important;
  min-width: 20px !important;
  padding: 0 4px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
}

:deep(.v-badge__badge.primary) {
  background-color: rgba(59, 130, 246, 0.3) !important;
  color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

/* Optional: Add hover effect for the badge */
:deep(.custom-hover:hover .v-badge__badge) {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

:deep(.inline-url) {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  padding: 2px 6px;
  border-radius: 4px;
  background: rgba(0, 75, 251, 0.1);
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

:deep(.inline-url:hover) {
  background: rgba(0, 75, 251, 0.15);
  text-decoration: underline;
}

:deep(.inline-url)::before {
  content: '🔗';
  margin-right: 4px;
  font-size: 0.9em;
}

:deep(.inline-entity) {
  display: inline-flex;
  align-items: center;
  color: #2563eb;
  text-decoration: none;
  padding: 2px 6px;
  border-radius: 4px;
  background: rgba(37, 99, 235, 0.1);
  transition: all 0.2s ease;
  font-size: 0.9em;
  font-family: monospace;
}

:deep(.inline-entity:hover) {
  background: rgba(37, 99, 235, 0.15);
  text-decoration: underline;
}

:deep(.inline-entity)::before {
  content: '🔍';
  margin-right: 4px;
  font-size: 0.9em;
}

/* Style adjustments for when URLs or entities are inside highlights */
:deep([class*="highlight-"] .inline-url),
:deep([class*="highlight-"] .inline-entity) {
  background: rgba(255, 255, 255, 0.2);
  color: inherit;
}

:deep([class*="highlight-"] .inline-url:hover),
:deep([class*="highlight-"] .inline-entity:hover) {
  background: rgba(255, 255, 255, 0.3);
}

.rag-tokens-container :deep(.v-badge__badge) {
  font-size: 10px !important;
  height: 20px !important;
  min-width: 20px !important;
  padding: 24px 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
}

/* Optional: Add specific badge styles for collapsed state */
.rag-tokens-container.collapsed :deep(.v-badge__badge) {
  padding: 24px !important;
  /* Add any specific styles for collapsed state */
}

:deep(.citation-wrapper) {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  position: relative;
}

:deep(.citation-source) {
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 12px;
  margin-left: 4px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

/* Light theme */
:deep(.citation-source) {
  color: rgba(71, 85, 105, 0.9);  /* Slate for readability */
  background: rgba(226, 232, 240, 0.5);  /* Light slate background */
  border: 1px solid rgba(71, 85, 105, 0.1);
}

:deep(.citation-source:hover) {
  background: rgba(226, 232, 240, 0.8);
  transform: translateY(-1px);
}

/* Dark theme */
:deep(.theme--dark .citation-source) {
  color: rgba(255, 255, 255, 0.7);
  background: rgba(71, 85, 105, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.08);
}

:deep(.theme--dark .citation-source:hover) {
  background: rgba(71, 85, 105, 0.35);
  transform: translateY(-1px);
}

/* Inside highlights */
:deep([class*="highlight-"] .citation-source) {
  background: rgba(71, 85, 105, 0.1);
  border-color: rgba(71, 85, 105, 0.15);
  color: inherit;
}

:deep(.theme--dark [class*="highlight-"] .citation-source) {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.05);
  color: inherit;
}

:deep(.price-chips) {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 2px 0;
  padding: 4px;
  background: rgba(59, 130, 246, 0.1);
  border-radius: 4px;
}

:deep(.price-chip) {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  padding: 2px 6px;
  background: rgba(16, 185, 129, 0.2);
  color: #047857;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

:deep(.price-chip:hover) {
  background: rgba(16, 185, 129, 0.3);
  transform: translateY(-1px);
}

:deep(.price-icon) {
  opacity: 0.8;
  width: 10px;
  height: 10px;
  margin-right: 2px;
}

/* Dark theme */
.theme--dark :deep(.price-chip) {
  background: rgba(16, 185, 129, 0.3);
  color: #ecfdf5;
}

.theme--dark :deep(.price-chips) {
  background: rgba(59, 130, 246, 0.15);
}

:deep(.href-chips) {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 2px 0;
  padding: 4px;
  background: rgba(59, 130, 246, 0.1);
  border-radius: 4px;
}

:deep(.href-chip) {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  padding: 2px 6px;
  background: rgba(59, 130, 246, 0.15);
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.2s ease;
}

:deep(.href-chip:hover) {
  background: rgba(59, 130, 246, 0.25);
  transform: translateY(-1px);
}

:deep(.href-icon) {
  opacity: 0.8;
  width: 10px;
  height: 10px;
  margin-right: 2px;
}

/* Dark theme adjustments */
.theme--dark :deep(.href-chip) {
  background: rgba(59, 130, 246, 0.25);
}

.theme--dark :deep(.href-chip:hover) {
  background: rgba(59, 130, 246, 0.35);
}

/* Combine chips in a nice layout */
:deep(.citation-wrapper > .price-chips),
:deep(.citation-wrapper > .href-chips) {
  margin: 2px 4px;
}


.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(59, 130, 246, 0.2);
}

.category-title-group {
  display: flex;
  align-items: center;
}

.category-actions {
  margin-left: auto;
}

.expand-btn {
  margin: 0 !important;
  font-size: 0.85rem !important;
}

.link-count {
  font-size: 0.8rem;
  opacity: 0.7;
  margin-left: 4px;
}

.feature-controls {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.feature-controls > * {
  margin-right: 0.75rem;
}

.feature-controls > *:last-child {
  margin-right: 0;
}

.nav-controls {
  display: flex;
  align-items: center;
}

.scroll-controls {
  display: flex;
  align-items: center;
  background: rgba(var(--v-theme-surface-variant), 0.1);
  border-radius: 8px;
  padding: 0 8px;
  margin-left: 8px;
  transition: all 0.3s ease;
}

.control-btn {
  margin: 0 2px !important;
}

/* Customize slider appearance */
.speed-slider :deep(.v-slider__thumb) {
  width: 16px;
  height: 16px;
}

.speed-slider :deep(.v-slider__track-background) {
  height: 4px;
}

.speed-slider :deep(.v-slider__track-fill) {
  height: 4px;
}

/* Theme-specific styles */
.theme--dark .scroll-controls {
  background: #1e1e1e;
}

.theme--light .scroll-controls {
  background: rgba(0, 0, 0, 0.05);
}

/* Animation for controls */
.scroll-controls {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hover effects */
.control-btn:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease;
}

.v-slider__thumb-label {
  background-color: var(--v-primary-base) !important;
}

.speed-slider :deep(.v-slider__thumb-label) {
  display: none;
}

.speed-slider {
  width: 50px;
  margin: 0 8px;
  position: relative;
}

.search-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(135deg, rgba(99, 102, 241, 0.08) 0%, rgba(99, 102, 241, 0.12) 100%);
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.results-count {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: rgba(99, 102, 241, 0.9);
}
/* Overall grid layout for results */
.search-results-grid {
  display: grid;
  grid-template-columns: 1fr;  /* Could be repeat(auto-fit, minmax(600px, 1fr)) for wider screens */
  gap: 1rem;
}

/* Individual result card using grid areas for structure */
.search-result {
  display: grid;
  grid-template-areas:
    "image title     date"
    "image url       url"
    "image description description";
  grid-template-columns: auto 1fr auto;
  padding: 2rem 3rem;
  background: linear-gradient(
    135deg,
    rgba(99, 102, 241, 0.08) 0%,
    rgba(99, 102, 241, 0.14) 100%
  );
  border: 1px solid rgba(99, 102, 241, 0.15);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.result-image {
  grid-area: image;
  width: 120px;
  height: 90px;
  margin-right: 1rem;
}

.result-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.placeholder-svg {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.result-title {
  grid-area: title;
  font-weight: 500;
  font-size: 1.1rem;
  padding-right: 1rem;
}

.result-date {
  grid-area: date;
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
  white-space: nowrap;
}

.result-url {
  grid-area: url;
  font-size: 0.85rem;
  color: rgba(99, 102, 241, 0.7);
  text-decoration: none;
  margin: 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-description {
  grid-area: description;
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
}

.theme--dark .search-result {
  background: #0f172a;
  border-color: rgba(255, 255, 255, 0.1);
}

.theme--dark .search-result:hover {
  background: #1a2540;
}

.delay-display {
  font-size: 12px;
  color: rgba(255,255,255,0.7);
  margin-left: 4px;
}

.ui-controls-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(167, 139, 250, 0.2);
}

.ui-controls {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.universal-scroll-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.5rem;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.scroll-controls {
  display: flex;
  align-items: center;
  background: rgba(var(--v-theme-surface-variant), 0.1);
  border-radius: 8px;
  padding: 0 8px;
  margin-left: 8px;
}

.delay-display {
  min-width: 2.5em;
  text-align: right;
  font-size: 0.75rem;
  opacity: 0.7;
}

/* Update tooltip styles to ensure proper z-index and visibility */
:deep(.v-tooltip__content) {
  z-index: 1000 !important; /* Ensure tooltips appear above other elements */
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.8) !important;
  color: white !important;
  padding: 8px 12px !important;
  border-radius: 6px !important;
  font-size: 0.875rem !important;
  max-width: 300px !important;
  pointer-events: none !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

/* Ensure tooltip container has proper z-index */
:deep(.v-tooltip) {
  z-index: 1000 !important;
  position: relative !important;
}

/* Update positioning context for tooltip triggers */
.ui-controls,
.nav-controls,
.feature-controls {
  position: relative !important;
  z-index: 10 !important;
}

/* Ensure proper stacking context for tooltip triggers */
.v-btn,
.v-icon {
  position: relative !important;
  z-index: 10 !important;
}
:deep(.url-section) {
  margin: 0.75rem 0;
  padding: 0.5rem 0.75rem;
  background: rgba(239, 68, 68, 0.08);  /* Softer red background */
  border-radius: 6px;
}

:deep(.url-title) {
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  border-left: 2px solid rgba(239, 68, 68, 0.8);  /* Stronger red border */
}

:deep(.url-description) {
  font-size: 0.95em;
  line-height: 1.5;
  padding-left: 0.5rem;
  border-left: 2px solid rgba(239, 68, 68, 0.4);  /* Softer red border */
}

:deep(.inline-url-wrapper) {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  background: rgba(239, 68, 68, 0.08);  /* Matching red background */
  padding: 2px 6px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

:deep(.inline-url-wrapper:hover) {
  background: rgba(239, 68, 68, 0.15);  /* Slightly stronger on hover */
  text-decoration: none;
}

/* Add subtle indicator that it's clickable */
:deep(.inline-url-wrapper::after) {
  content: '↗';
  display: inline-block;
  margin-left: 4px;
  opacity: 0.6;
  font-size: 0.9em;
}

:deep(.inline-url-wrapper:hover::after) {
  opacity: 1;
}

.modern-links-section {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 12px;
}


/* Style for the links container */
.links-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.links-card {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.links-card:hover {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.card-header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.header-content h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1.5rem;
  max-height: 400px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.links-grid.expanded {
  max-height: none;
}

.link-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.link-card:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.08);
}

.link-card-content {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.link-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
}

.link-details {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.link-url {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-meta {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.5);
}

.card-footer {
  padding: 0.75rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
}

.visit-btn {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.link-card:hover .visit-btn {
  opacity: 1;
}

@media (max-width: 768px) {
  .links-grid {
    grid-template-columns: 1fr;
  }

  .modern-links-section {
    padding: 1rem;
  }

  .link-card-content {
    padding: 0.75rem;
  }

  .visit-btn {
    opacity: 1;
  }
}

/* Common styles for all highlights */
:deep([class*="highlight-"]) {
  position: relative;
  border-radius: 2px;
  padding: 0.15em 0.3em;
  margin: 0;
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline;
  line-height: 1.6;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

/* Current highlight style - academic paper look */
:deep(.highlight-current) {
  background: linear-gradient(
    120deg,
    rgba(167, 139, 250, 0.3) 0%,
    rgba(167, 139, 250, 0.2) 100%
  );
  border-bottom: 2px solid rgba(167, 139, 250, 0.4);
  box-shadow:
    inset 0 -2px 0 rgba(167, 139, 250, 0.2),
    0 2px 4px rgba(0, 0, 0, 0.1);
  animation: gentleGlow 2s ease-in-out infinite;
  font-size: 1rem;
}

/* Subtle animation for current highlight */
@keyframes gentleGlow {
  0%, 100% {
    background: linear-gradient(
      120deg,
      rgba(167, 139, 250, 0.3) 0%,
      rgba(167, 139, 250, 0.2) 100%
    );
  }
  50% {
    background: linear-gradient(
      120deg,
      rgba(167, 139, 250, 0.35) 0%,
      rgba(167, 139, 250, 0.25) 100%
    );
  }
}

/* Dark theme adjustments */
.theme--dark :deep(.highlight-current) {
  background: linear-gradient(
    120deg,
    rgba(167, 139, 250, 0.25) 0%,
    rgba(167, 139, 250, 0.15) 100%
  );
  border-bottom: 2px solid rgba(167, 139, 250, 0.3);
}

/* Other highlights should be more subtle */
:deep(.highlight-search),
:deep(.highlight-user-query),
:deep(.highlight-relevant-section) {
  background: rgba(167, 139, 250, 0.15);
  border-bottom: 1px solid rgba(167, 139, 250, 0.2);
}
</style>
