<template>
  <div class="citation-renderer" ref="container">
    <div v-html="processedHtml" @click="handleClick"></div>
    <transition name="fade">
      <div v-if="activeTooltip" class="modal-overlay" @click="closeTooltip"></div>
    </transition>
    <transition name="slide">
      <div v-if="activeTooltip"
           class="tooltip-modal"
           :style="tooltipStyle"
           v-html="activeTooltip.content">
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CitationRenderer',
  props: {
    html: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      formattedHtml: '',
      activeTooltip: null,
      isMobile: false,
      autoScrollInterval: null,
      currentCitationIndex: 0,
      citations: [],
      isPaused: false,  // New flag to track pause state
      lastPosition: 0   // Store last position for resuming
    };
  },

  computed: {
    tooltipStyle() {
      if (this.isMobile) {
        return {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          maxHeight: '60vh'
        }
      }

      if (!this.activeTooltip) return {}

      const citation = this.activeTooltip.element.getBoundingClientRect()
      const container = this.$refs.container.getBoundingClientRect()
      const windowWidth = window.innerWidth
      const tooltipWidth = 250

      // Position to the right by default
      let left = `${citation.right + 10}px`

      // If tooltip would overflow right edge, position to the left
      if (citation.right + tooltipWidth + 10 > windowWidth) {
        left = `${citation.left - tooltipWidth - 10}px`
      }

      return {
        position: 'fixed',
        top: `${citation.top}px`,
        left,
        maxHeight: '300px'
      }
    },

  },

  watch: {
    html: {
      immediate: true,
      handler(newHtml) {
        if (!newHtml) return;
        this.formattedHtml = this.processLargeCitationGroups(newHtml);
        this.processHtml(newHtml);
      }
    }
  },

  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);

    // Add hover event handler that triggers the same behavior as click
    this.$refs.container.addEventListener('mouseover', (event) => {
      if (this.isMobile) return; // Keep click-only for mobile

      const citationLink = event.target.closest('.citation-link');
      if (citationLink) {
        // Simply call the existing click handler
        this.handleCitationClick(event);
      }
    });

    // Optional: Clean up highlights when hover ends
    this.$refs.container.addEventListener('mouseout', (event) => {
      if (this.isMobile) return;

      const citationLink = event.target.closest('.citation-link');
      if (citationLink) {
        this.removeHighlights();
        citationLink.classList.remove('citation-link-active');
      }
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
    // Clean up event listeners if needed
    this.stopAutoScroll();

  },

  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768
    },

    processHtml(html) {
      const processMatches = (content) => {
        // Extract all HREF matches to create set of terms to remove
        const hrefMatches = [...content.matchAll(/\[HREF:\s*([^|]+)\|(https?:\/\/[^\]]+)\]/g)];
        const termsToRemove = new Set(hrefMatches.map(match => match[1]));

        // Remove duplicated terms from end
        termsToRemove.forEach(term => {
          const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          content = content.replace(new RegExp(`\\s*${escapedTerm}\\s*$`), '');
        });

        // Replace HREF syntax
        return content.replace(/\[HREF:\s*([^|]+)\|https?:\/\/[^\]]+\]/g, '');
      };

      html = processMatches(html);

      // Create a temporary div to manipulate the HTML
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // Process all citation links
      const citations = tempDiv.querySelectorAll('.citation-link');
      citations.forEach(citation => {
        if (citation.dataset.sourceText) {
          // Process the source text
          citation.dataset.sourceText = processMatches(citation.dataset.sourceText.trim());

          // Remove any existing v-tooltip directives
          citation.removeAttribute('v-tooltip');

          // Add scrollable tooltip class
          citation.classList.add('scrollable-tooltip');
        }
      });

      // Get the processed HTML
      this.processedHtml = tempDiv.innerHTML;
    },
    handleClick(event) {
      // if not mobile, trigger handleCitationClick
      if (!this.isMobile) {
        this.handleCitationClick(event)
        return
      }
      const citation = event.target.closest('.citation-link')
      if (!citation) return

      event.stopPropagation()

      // Toggle no-hover class for all citation links
      document.querySelectorAll('.citation-link').forEach(link => {
        link.classList.add('no-hover')
      })

      if (this.activeTooltip?.element === citation) {
        this.closeTooltip()
        return
      }

      this.activeTooltip = {
        element: citation,
        content: citation.dataset.sourceText
      }
      if (citation.dataset.tooltipHeader) {
        this.activeTooltip.content = citation.dataset.tooltipHeader + this.activeTooltip.content;
      }
    },

    closeTooltip() {
      // Remove no-hover class from all citation links
      document.querySelectorAll('.citation-link').forEach(link => {
        link.classList.remove('no-hover')
      })
      this.activeTooltip = null
    },

    safeDecodeSourceText(text) {
        if (!text) return '';

        try {
            // First try direct decodeURIComponent
            return decodeURIComponent(text);
        } catch (uriError) {
            try {
                // If that fails, try to clean up the text first
                const cleaned = text
                    .replace(/%(?![0-9A-Fa-f]{2})/g, '%25') // Fix incomplete percent encoding
                    .replace(/[^ -~]/g, encodeURIComponent) // Re-encode non-ASCII using printable ASCII range
                    .replace(/\+/g, ' '); // Handle plus signs

                return decodeURIComponent(cleaned);
            } catch (fallbackError) {
                // If all decoding fails, return the original text
                console.warn('Failed to decode source text:', text, fallbackError);
                return text;
            }
        }
    },
    processLargeCitationGroups(html) {
      if (!html) return '';

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      // First pass: Process each citation link to show metadata in tooltip
      const citations = tempDiv.querySelectorAll('.citation-link');
      citations.forEach(citation => {
          try {
              // Decode and sanitize metadata before parsing
              const rawMetadata = citation.dataset.metadata || '{}';
              const cleanMetadata = this.sanitizeJsonString(rawMetadata);
              const metadata = JSON.parse(cleanMetadata);

              // Create formatted metadata header for display only
              const metadataHeader = this.formatMetadataHeader(metadata);
              citation.dataset.tooltipHeader = metadataHeader;

              // Store back sanitized metadata
              citation.dataset.metadata = JSON.stringify(metadata);

              // Safely decode source text if present
              if (citation.dataset.sourceText) {
                  citation.dataset.sourceText = this.safeDecodeSourceText(citation.dataset.sourceText);
              }
          } catch (error) {
              console.warn('Error processing citation metadata:', error, 'Raw metadata:', citation.dataset.metadata);
              // Set fallback metadata to prevent future errors
              citation.dataset.metadata = '{}';
          }
      });

      // Second pass: Find all citation groups (consecutive citation-link spans)
      const spans = Array.from(tempDiv.querySelectorAll('.citation-link'));

      let currentIndex = 0;
      while (currentIndex < spans.length) {
        try {
          let groupEnd = currentIndex;

          while (groupEnd + 1 < spans.length &&
                this.areSpansAdjacent(spans[groupEnd], spans[groupEnd + 1])) {
            groupEnd++;
          }

          const groupSize = groupEnd - currentIndex + 1;
          if (groupSize > 4) {
            this.createCollapsibleGroup(
              spans.slice(currentIndex, currentIndex + 4),
              spans.slice(currentIndex + 4, groupEnd + 1),
              tempDiv
            );
          }

          currentIndex = groupEnd + 1;
        } catch (error) {
          console.warn('Error processing citation groups:', error);
          currentIndex++;
        }
      }
      return tempDiv.innerHTML;
    },

    sanitizeJsonString(jsonString) {
      try {
          // Remove any HTML tags.
          const withoutTags = jsonString.replace(/<[^>]*>/g, '');

          // Decode HTML entities
          const decoded = this.decodeHtmlEntities(withoutTags);

          // Fix any double-encoded quotes
          const fixedQuotes = decoded.replace(/&quot;/g, '"')
                                  .replace(/\\"/g, '"')
                                  .replace(/""/, '"');

          // Ensure it's valid JSON structure
          if (!fixedQuotes.startsWith('{')) {
              return '{}';
          }

          return fixedQuotes;
      } catch (error) {
          console.warn('Error sanitizing JSON string:', error);
          return '{}';
      }
    },

    decodeHtmlEntities(str) {
      const txt = document.createElement('textarea');
      txt.innerHTML = str;
      return txt.value;
    },

    formatMetadataHeader(metadata) {
      if (!metadata || Object.keys(metadata).length === 0) return '';

      let header = '╭──────────── Source Info ────────────╮\n';

      if (metadata.type) {
        header += `Type: ${this.capitalizeFirst(metadata.type)}\n`;
      }

      if (metadata.title) {
        header += `Title: ${metadata.title}\n`;
      }

      if (metadata.fileName) {
        header += `File: ${metadata.fileName}\n`;
      }

      if (metadata.date) {
        header += `Date: ${new Date(metadata.date).toLocaleDateString()}\n`;
      }

      if (metadata.url) {
        header += `URL: ${metadata.url}\n`;
      }

      header += '╰─────────────────────────────────────╯\n\n';

      return header;
    },

    capitalizeFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    areSpansAdjacent(span1, span2) {
      // Check if there's only whitespace or commas between spans
      let node = span1.nextSibling;
      while (node && node !== span2) {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent.trim();
          if (text && text !== ',') return false;
        } else if (node.nodeType === Node.ELEMENT_NODE && !node.classList.contains('citation-link')) {
          return false;
        }
        node = node.nextSibling;
      }
      return true;
    },

    createCollapsibleGroup(visibleSpans, hiddenSpans, container) {
      if (!hiddenSpans.length) return;

      const groupId = Math.random().toString(36).substr(2, 9);

      // Create button container
      const buttonContainer = document.createElement('span');
      buttonContainer.className = 'citation-button-container';

      // Create expand/collapse buttons...
      const expandButton = document.createElement('span');
      expandButton.className = 'citation-expand';
      expandButton.dataset.group = groupId;
      expandButton.setAttribute('role', 'button');
      expandButton.setAttribute('tabindex', '0');
      expandButton.textContent = '...';

      const collapseButton = document.createElement('span');
      collapseButton.className = 'citation-collapse';
      collapseButton.dataset.group = groupId;
      collapseButton.setAttribute('role', 'button');
      collapseButton.setAttribute('tabindex', '0');
      collapseButton.style.display = 'none';
      collapseButton.textContent = '←';

      buttonContainer.appendChild(expandButton);
      buttonContainer.appendChild(collapseButton);

      // Create hidden group
      const hiddenGroup = document.createElement('span');
      hiddenGroup.className = 'citation-group-hidden';
      hiddenGroup.dataset.group = groupId;
      hiddenGroup.style.display = 'none';

      // Process spans with safe decoding
      const processSpan = (span) => {
        try {
          let sourceText = span.dataset.sourceText;
          if (sourceText) {
            span.dataset.sourceText = this.safeDecodeSourceText(sourceText).trim();
          }
          return span.cloneNode(true);
        } catch (error) {
          console.warn('Error processing span:', error);
          return span.cloneNode(true);
        }
      };

       // Add spans to hidden group
       hiddenSpans.forEach(span => {
        try {
            hiddenGroup.appendChild(processSpan(span));
        } catch (error) {
            console.warn('Error appending span:', error);
        }
      });

      // Insert elements
      const lastVisibleSpan = visibleSpans[visibleSpans.length - 1];
      lastVisibleSpan.after(buttonContainer, hiddenGroup);

      // Remove original hidden spans
      hiddenSpans.forEach(span => {
        try {
            span.remove();
        } catch (error) {
            console.warn('Error removing span:', error);
        }
      });
    },
    handleExpandCollapse(target) {
      const groupId = target.dataset.group;
      const hiddenGroup = document.querySelector(`.citation-group-hidden[data-group="${groupId}"]`);
      const buttonContainer = target.closest('.citation-button-container');
      const expandButton = buttonContainer.querySelector('.citation-expand');
      const collapseButton = buttonContainer.querySelector('.citation-collapse');

      if (target.classList.contains('citation-expand')) {
        if (hiddenGroup && collapseButton) {
          expandButton.style.display = 'none';
          hiddenGroup.style.display = 'inline';
          collapseButton.style.display = 'inline-flex';
        }
      } else if (target.classList.contains('citation-collapse')) {
        if (hiddenGroup && expandButton) {
          collapseButton.style.display = 'none';
          hiddenGroup.style.display = 'none';
          expandButton.style.display = 'inline-flex';
        }
      }
    },
    handleCitationClick(event) {
      if (event && typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }
      const target = event.target;

      if (target.classList.contains('citation-expand') ||
          target.classList.contains('citation-collapse')) {
          this.handleExpandCollapse(target);
          return;
      }

      const citationLink = target.closest('.citation-link');
      if (!citationLink) return;

      try {
        const citation = citationLink.dataset.citation;

        // Safely decode source text
        let sourceText = '';
        try {
            const rawSourceText = citationLink.dataset.sourceText || '';
            sourceText = this.decodeHtmlEntities(decodeURIComponent(rawSourceText));
        } catch (decodeError) {
            sourceText = citationLink.dataset.sourceText || '';
        }

        // Safely parse metadata
        let metadata = {};
        try {
            const cleanMetadata = this.sanitizeJsonString(citationLink.dataset.metadata || '{}');
            metadata = JSON.parse(cleanMetadata);
        } catch (metadataError) {
            console.warn('Error parsing metadata:', metadataError);
        }

        // Remove previous highlights
        this.removeHighlights();

        // Add active class to citation
        citationLink.classList.add('citation-link-active');

        // Handle different citation types
        if (metadata && metadata.type && metadata.id) {
            this.$store.dispatch('websocket/setActiveObject', {
                type: this.getObjectType(metadata.type),
                id: metadata.id,
                rag_strategy: 'llm_passthrough'
            });
        }

        // Highlight text if available
        if (sourceText) {
            this.highlightInRag(sourceText);
        }

        this.$emit('citation-click', {
            citation,
            element: citationLink,
            sourceText,
            metadata
        });
      } catch (error) {
          console.error('Error handling citation click:', error);
      }
    },

    getObjectType(type) {
      const typeMap = {
          'file': 'FileSubmission',
          'text': 'TextSubmission',
          'youtube': 'YouTubeTranscriptSubmission',
          'search': 'ChatMessage',
          'ecrag': 'ChatMessage'
      };
      return typeMap[type] || 'ChatMessage';
    },

    // Update the highlightInRag method for smoother scrolling
    highlightInRag(text) {
      const ragContainer = document.querySelector('.rag-container');
      if (!ragContainer) return;

      const walker = document.createTreeWalker(
        ragContainer,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      let node;
      const highlights = [];

      // Find all matching text nodes first
      while ((node = walker.nextNode())) {
        if (node.textContent.includes(text)) {
          const range = document.createRange();
          range.setStart(node, node.textContent.indexOf(text));
          range.setEnd(node, node.textContent.indexOf(text) + text.length);

          const highlight = document.createElement('span');
          highlight.className = 'citation-highlight';
          range.surroundContents(highlight);
          highlights.push(highlight);
        }
      }

      // If we found highlights, scroll to the first one
      if (highlights.length > 0) {
        highlights[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    },

    removeHighlights() {
      document.querySelectorAll('.citation-link-active')
        .forEach(el => el.classList.remove('citation-link-active'));

      document.querySelectorAll('.citation-highlight')
        .forEach(el => {
          const parent = el.parentNode;
          parent.replaceChild(document.createTextNode(el.textContent), el);
          parent.normalize();
        });
    },

    toggleAutoScroll(shouldPlay, delay = 5000) {
      if (shouldPlay) {
        this.startAutoScroll(delay);
      } else {
        this.pauseScroll();
      }
    },

    // Inside the CitationRenderer component methods:

    triggerCitation(citationElement) {
      // Remove previous highlights and close any open tooltips
      this.removeHighlights();
      this.closeTooltip();

      // Create synthetic event
      const syntheticEvent = {
        stopPropagation: () => {},
        target: citationElement
      };

      if (this.isMobile) {
        // For mobile, set the tooltip content
        this.activeTooltip = {
          element: citationElement,
          content: citationElement.dataset.sourceText
        };

        if (citationElement.dataset.tooltipHeader) {
          this.activeTooltip.content = citationElement.dataset.tooltipHeader + this.activeTooltip.content;
        }

        // Add no-hover class to prevent hover tooltips
        document.querySelectorAll('.citation-link').forEach(link => {
          link.classList.add('no-hover');
        });
        // No early closure - let tooltip stay until next citation
      } else {
        // For desktop, trigger the normal citation click
        this.handleCitationClick(syntheticEvent);
      }

      // Scroll the citation into view
      citationElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });

      // Add active class
      citationElement.classList.add('citation-link-active');
    },

    startAutoScroll(delay = 5000) {
      this.citations = this.$el.querySelectorAll('.citation-link');
      if (!this.citations.length) return;

      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
      }

      // Resume from last position if paused
      if (this.isPaused) {
        this.currentCitationIndex = this.lastPosition;
      } else {
        this.currentCitationIndex = 0;
        this.closeTooltip();
      }

      this.isPaused = false;
      this.triggerCitation(this.citations[this.currentCitationIndex]);

      this.autoScrollInterval = setInterval(() => {
        this.currentCitationIndex++;
        this.lastPosition = this.currentCitationIndex;

        if (this.currentCitationIndex >= this.citations.length) {
          this.stopAutoScroll();
          this.$emit('auto-scroll-complete');
          return;
        }

        this.triggerCitation(this.citations[this.currentCitationIndex]);
      }, delay);
    },

    stopAutoScroll() {
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
        this.autoScrollInterval = null;
      }
      this.currentCitationIndex = 0;
      this.lastPosition = 0;
      this.isPaused = false;
      this.closeTooltip(); // Ensure tooltip is closed
      this.$emit('auto-scroll-complete');
    },

    pauseScroll() {
      if (this.autoScrollInterval) {
        clearInterval(this.autoScrollInterval);
        this.autoScrollInterval = null;
      }
      this.isPaused = true;
      this.lastPosition = this.currentCitationIndex;
      this.closeTooltip(); // Close tooltip when pausing
    },
  }
});
</script>

<style scoped>
:deep(.citation-link) {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4em;
  height: 1.4em;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: rgba(37, 99, 235, 0.2);
  border-radius: 999px;
  padding: 0 0.4em;
  margin: 0 0.15em;
  text-decoration: none;
  position: relative;
  z-index: 1;
  user-select: none;
  backdrop-filter: blur(4px);
  transition: z-index 0s, all 0.15s ease;
}

/* Raise z-index when hovered */
:deep(.citation-link:hover) {
  z-index: 10002;
  pointer-events: auto !important;
}

:deep(.citation-link::after) {
  content: attr(data-source-text);
  position: absolute;
  height: 300px;
  overflow-y: auto !important;
  width: 250px;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 10px;
  /* Modern background with gradient */
  background: linear-gradient(
    145deg,
    rgba(30, 41, 59, 0.95),
    rgba(15, 23, 42, 0.98)
  );
  border: 1px solid rgba(148, 163, 184, 0.1);
  color: #f8fafc;
  padding: 1rem;
  border-radius: 0.75rem;
  font-size: 0.875rem;
  white-space: pre-wrap;
  pointer-events: auto !important;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  backdrop-filter: blur(8px);
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.2),
    0 2px 4px -1px rgba(0, 0, 0, 0.1),
    inset 0 1px 0 0 rgba(148, 163, 184, 0.05);
  line-height: 1.4;
  text-align: left;
  -webkit-overflow-scrolling: touch;
}

/* Add subtle highlight effect on hover */
:deep(.citation-link:hover::after) {
  box-shadow:
    0 8px 12px -2px rgba(0, 0, 0, 0.3),
    0 4px 6px -2px rgba(0, 0, 0, 0.2),
    inset 0 1px 0 0 rgba(148, 163, 184, 0.1);
}

/* Show tooltip on hover */
:deep(.citation-link:hover::after) {
  opacity: 1;
  visibility: visible;
  pointer-events: auto !important;
  overflow: auto !important;
}

/* Desktop-only adjustments for right edge */
@media screen and (min-width: 769px) {
  :deep(.citation-link::after) {
    @media (hover: hover) {
      &:hover {
        left: auto;
        right: 100%;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  :deep(.citation-link::after) {
    position: fixed;
    width: 90vw;
    max-height: 50vh;
    left: 50% !important;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    max-width: calc(100vw - 32px);
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    z-index: 10001;
  }

  :deep(.citation-link.tooltip-visible::before) {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
  }
}

/* Height adjustments */
@media screen and (max-height: 768px) {
  :deep(.citation-link::after) {
    max-height: 40vh;
  }
}

/* Very small screens */
@media screen and (max-width: 320px) {
  :deep(.citation-link::after) {
    width: calc(100vw - 24px);
    max-height: 60vh;
  }
}

/* Active state */
:deep(.citation-link-active) {
  color: #ffffff;
  background-color: rgba(59, 130, 246, 0.5);
  border-color: rgba(147, 197, 253, 0.5);
  transform: scale(1.25);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  font-weight: 500;
  z-index: 10002;
}

/* Dark theme variation */
:deep([class*='dark'] .citation-link-active) {
  background-color: rgba(59, 130, 246, 0.6);
  border-color: rgba(147, 197, 253, 0.6);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
}

/* Expand/Collapse buttons */
:deep(.citation-expand),
:deep(.citation-collapse) {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4em;
  height: 1.4em;
  font-size: 0.75rem;
  font-weight: 500;
  color: #cde0ff;
  background-color: rgba(230, 240, 255, 0.1);
  border-radius: 999px;
  padding: 0 0.4em;
  margin: 0 0.15em;
  transition: all 0.15s ease;
  user-select: none;
}

:deep(.citation-expand:hover),
:deep(.citation-collapse:hover) {
  background-color: rgba(230, 240, 255, 0.2);
  color: #e2edff;
}

/* Hidden citation group */
:deep(.citation-group-hidden) {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease;
}

:deep(.citation-group-hidden[style*="display: inline"]) {
  opacity: 1;
  max-height: none;
}

/* Highlight */
:deep(.citation-highlight) {
  background-color: rgba(205, 224, 255, 0.15);
  border-radius: 4px;
  padding: 2px 0;
  transition: background-color 0.3s ease;
}

/* Scrollbar styling */
:deep(.citation-link::after::-webkit-scrollbar) {
  width: 4px;
}

:deep(.citation-link::after::-webkit-scrollbar-track) {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
}

:deep(.citation-link::after::-webkit-scrollbar-thumb) {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

/* Mobile tooltip visibility */
:deep(.citation-link.tooltip-visible) {
  position: relative;
  z-index: 10002;
}

.tooltip-modal {
  position: fixed;
  background: linear-gradient(
    145deg,
    rgba(30, 41, 59, 0.95),
    rgba(15, 23, 42, 0.98)
  );
  color: #f8fafc;
  padding: 1rem;
  border-radius: 0.375rem;
  z-index: 10004; /* higher than overlay */
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Optimize mobile tooltip positioning */
@media screen and (max-width: 768px) {
  .tooltip-modal {
    width: 90vw;
    max-height: 60vh;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  :deep(.citation-link.no-hover),
  :deep(.citation-link.tooltip-visible) {
    z-index: 1 !important; /* Force lower z-index on mobile */
  }

  /* Ensure modal doesn't interfere with controls */
  .tooltip-modal {
    z-index: 10004;
    pointer-events: auto;
  }

  /* Smoother animations for auto-scroll */
  .slide-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-leave-active {
    transition: all 0.2s ease-in;
  }

  .slide-enter-from,
  .slide-leave-to {
    opacity: 0;
    transform: translate(-50%, -40%) !important;
  }

  /* Active citation styling during auto-scroll */
  :deep(.citation-link-active) {
    z-index: 10002;
    transform: scale(1.1);
    transition: all 0.3s ease;
  }
}

/* Ensure modal overlay works with auto-scroll */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10003; /* Above tooltip */
  transition: opacity 0.3s ease;
}

/* Transitions */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter, .slide-leave-to {
  opacity: 0;
  transform: translate(-50%, -40%);
}

/* Hide citation link tooltip on hover on mobile. */
:deep(.citation-link.no-hover::after) {
  display: none !important;
}
/* Mobile optimization */
@media screen and (max-width: 768px) {
  .tooltip-modal {
    width: 90vw;
    max-height: 60vh;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

@media screen and (max-width: 768px) {
  :deep(.citation-link.no-hover::after),
  :deep(.citation-link.no-hover:hover::after) {
    display: none !important;
  }

  :deep(.citation-link:hover::after) {
    opacity: 0;
    visibility: hidden;
  }
}

/* Add to your existing styles */
:deep(.scrollable-tooltip::after) {
  overflow-y: auto !important;
  max-height: 300px !important;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1);
}

:deep(.scrollable-tooltip::after::-webkit-scrollbar) {
  width: 4px;
}

:deep(.scrollable-tooltip::after::-webkit-scrollbar-track) {
  background: rgba(255, 255, 255, 0.1);
}

:deep(.scrollable-tooltip::after::-webkit-scrollbar-thumb) {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

/* Make sure the tooltip pseudo-element can scroll */
:deep(.scrollable-tooltip::after) {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

</style>
